const phonePreList = [
    { c: '+86', n: '中国', p: 'CH' },
    {
        c: '+852',
        n: '香港特别行政区',
        p: 'HK'
    },
    {
        c: '+853',
        n: '澳门特别行政区',
        p: 'zhongguoaomentebiexingzhengqu'
    },
    { c: '+886', n: '台湾省', p: 'zhongguotaiwandiqu' },
    { c: '+355', n: '阿尔巴尼亚', p: 'aerbaniya' },
    { c: '+213', n: '阿尔及利亚', p: 'aerjiliya' },
    { c: '+93', n: '阿富汗', p: 'afuhan' },
    { c: '+54', n: '阿根廷', p: 'agenting' },
    { c: '+353', n: '爱尔兰', p: 'aierlan' },
    { c: '+20', n: '埃及', p: 'aiji' },
    { c: '+251', n: '埃塞俄比亚', p: 'aisaiebiya' },
    { c: '+372', n: '爱沙尼亚', p: 'aishaniya' },
    { c: '+297', n: '阿鲁巴', p: 'aluba' },
    { c: '+968', n: '阿曼', p: 'aman' },
    { c: '+376', n: '安道尔', p: 'andaoer' },
    { c: '+244', n: '安哥拉', p: 'angela' },
    { c: '+1264', n: '安圭拉', p: 'anguila' },
    { c: '+1268', n: '安提瓜和巴布达', p: 'antiguahebabuda' },
    { c: '+61', n: '澳大利亚', p: 'aodaliya' },
    { c: '+43', n: '奥地利', p: 'aodili' },
    { c: '+994', n: '阿塞拜疆', p: 'asaibaijiang' },
    { c: '+1246', n: '巴巴多斯', p: 'babaduosi' },
    { c: '+675', n: '巴布亚新几内亚', p: 'babuyaxinjineiya' },
    { c: '+1242', n: '巴哈马', p: 'bahama' },
    { c: '+375', n: '白俄罗斯', p: 'baieluosi' },
    { c: '+1441', n: '百慕大', p: 'baimuda' },
    { c: '+92', n: '巴基斯坦', p: 'bajisitan' },
    { c: '+595', n: '巴拉圭', p: 'balagui' },
    { c: '+973', n: '巴林', p: 'balin' },
    { c: '+507', n: '巴拿马', p: 'banama' },
    { c: '+359', n: '保加利亚', p: 'baojialiya' },
    { c: '+55', n: '巴西', p: 'baxi' },
    { c: '+1670', n: '北马里亚纳群岛', p: 'beimaliyanaqundao' },
    { c: '+229', n: '贝宁', p: 'beining' },
    { c: '+32', n: '比利时', p: 'bilishi' },
    { c: '+354', n: '冰岛', p: 'bingdao' },
    { c: '+267', n: '博茨瓦纳', p: 'bociwana' },
    { c: '+1', n: '波多黎各', p: 'boduolige' },
    { c: '+48', n: '波兰', p: 'bolan' },
    { c: '+591', n: '玻利维亚', p: 'boliweiya' },
    { c: '+501', n: '伯利兹', p: 'bolizi' },
    { c: '+975', n: '不丹', p: 'budan' },
    { c: '+226', n: '布基纳法索', p: 'bujinafasuo' },
    { c: '+257', n: '布隆迪', p: 'bulongdi' },
    { c: '+850', n: '朝鲜', p: 'chaoxian' },
    { c: '+240', n: '赤道几内亚', p: 'chidaojineiya' },
    { c: '+45', n: '丹麦', p: 'danmai' },
    { c: '+49', n: '德国', p: 'deguo' },
    { c: '+670', n: '东帝汶', p: 'dongdiwen' },
    { c: '+228', n: '多哥', p: 'duoge' },
    { c: '+1767', n: '多米尼加', p: 'duominijia' },
    { c: '+593', n: '厄瓜多尔', p: 'eguaduoer' },
    { c: '+291', n: '厄立特里亚', p: 'eliteliya' },
    { c: '+7', n: '俄罗斯', p: 'eluosi' },
    { c: '+33', n: '法国', p: 'faguo' },
    { c: '+298', n: '法罗群岛', p: 'faluoqundao' },
    { c: '+39', n: '梵蒂冈', p: 'fandigang' },
    { c: '+689', n: '法属波利尼西亚', p: 'fashubolinixiya' },
    { c: '+679', n: '斐济群岛', p: 'feiji' },
    { c: '+63', n: '菲律宾', p: 'feilvbin' },
    { c: '+358', n: '芬兰', p: 'fenlan' },
    { c: '+238', n: '佛得角', p: 'fodejiao' },
    { c: '+500', n: '福克兰', p: 'fukelanqundao' },
    { c: '+220', n: '冈比亚', p: 'gangbiya' },
    { c: '+242', n: '刚果（布）', p: 'gangguobu' },
    { c: '+243', n: '刚果（金）', p: 'gangguojin' },
    { c: '+299', n: '格陵兰', p: 'gelinglan' },
    { c: '+1473', n: '格林纳达', p: 'gelinnada' },
    { c: '+995', n: '格鲁吉亚', p: 'gelujiya' },
    { c: '+57', n: '哥伦比亚', p: 'gelunbiya' },
    { c: '+506', n: '哥斯达黎加', p: 'gesidalijia' },
    { c: '+1671', n: '关岛', p: 'guandao' },
    { c: '+53', n: '古巴', p: 'guba' },
    { c: '+592', n: '圭亚那', p: 'guiyana' },
    { c: '+509', n: '海地', p: 'haidi' },
    { c: '+82', n: '韩国', p: 'hanguo' },
    { c: '+7', n: '哈萨克斯坦', p: 'hasakesitan' },
    { c: '+382', n: '黑山', p: 'heishangongheguo' },
    { c: '+31', n: '荷兰', p: 'helan' },
    { c: '+504', n: '洪都拉斯', p: 'hongdoulasi' },
    { c: '+233', n: '加纳', p: 'jiana' },
    { c: '+1', n: '加拿大', p: 'jianada' },
    { c: '+855', n: '柬埔寨', p: 'jianpuzhai' },
    { c: '+241', n: '加蓬', p: 'jiapeng' },
    { c: '+253', n: '吉布提', p: 'jibuti' },
    { c: '+420', n: '捷克', p: 'jiekegongheguo' },
    { c: '+996', n: '吉尔吉斯斯坦', p: 'jierjisisitan' },
    { c: '+686', n: '基里巴斯', p: 'jilibasi' },
    { c: '+224', n: '几内亚', p: 'jineiya' },
    { c: '+245', n: '几内亚比绍', p: 'jineiyabishao' },
    { c: '+1345', n: '开曼群岛', p: 'kaimanqundao' },
    { c: '+237', n: '喀麦隆', p: 'kamailong' },
    { c: '+974', n: '卡塔尔', p: 'kataer' },
    { c: '+61', n: '科科斯群岛', p: 'kekesiqundao' },
    { c: '+385', n: '克罗地亚', p: 'keluodiya' },
    { c: '+269', n: '科摩罗', p: 'kemoluo' },
    { c: '+254', n: '肯尼亚', p: 'kenniya' },
    { c: '+225', n: '科特迪瓦', p: 'ketediwa' },
    { c: '+965', n: '科威特', p: 'keweite' },
    { c: '+682', n: '库克群岛', p: 'kukequndao' },
    { c: '+266', n: '莱索托', p: 'laisuotuo' },
    {
        c: '+856',
        n: '老挝',
        p: 'laoworenminminzhugongheguo'
    },
    { c: '+371', n: '拉脱维亚', p: 'latuoweiya' },
    { c: '+961', n: '黎巴嫩', p: 'libanen' },
    { c: '+231', n: '利比里亚', p: 'libiliya' },
    { c: '+218', n: '利比亚', p: 'libiya' },
    { c: '+423', n: '列支敦士登', p: 'liezhidunshideng' },
    { c: '+370', n: '立陶宛', p: 'litaowan' },
    { c: '+40', n: '罗马尼亚', p: 'luomaniya' },
    { c: '+352', n: '卢森堡', p: 'lusenbao' },
    { c: '+250', n: '卢旺达', p: 'luwangda' },
    { c: '+261', n: '马达加斯加', p: 'madajiasijia' },
    { c: '+960', n: '马尔代夫', p: 'maerdaifu' },
    { c: '+356', n: '马耳他', p: 'maerta' },
    { c: '+60', n: '马来西亚', p: 'malaixiya' },
    { c: '+265', n: '马拉维', p: 'malawei' },
    { c: '+223', n: '马里', p: 'mali' },
    { c: '+230', n: '毛里求斯', p: 'maoliqiusi' },
    { c: '+222', n: '毛里塔尼亚', p: 'maolitaniya' },
    { c: '+389', n: '马其顿', p: 'maqidun' },
    { c: '+692', n: '马绍尔群岛', p: 'mashaoerqundao' },
    { c: '+262', n: '马约特', p: 'mayuete' },
    { c: '+1', n: '美国', p: 'meiguo' },
    { c: '+1684', n: '美属萨摩亚', p: 'meishusamoya' },
    { c: '+976', n: '蒙古', p: 'menggu' },
    { c: '+880', n: '孟加拉', p: 'mengjialaguo' },
    { c: '+95', n: '缅甸', p: 'miandian' },
    { c: '+691', n: '密克罗尼西亚联邦', p: 'mikeluonixiyalianbang' },
    { c: '+51', n: '秘鲁', p: 'milu' },
    { c: '+373', n: '摩尔多瓦', p: 'moerduowa' },
    { c: '+212', n: '摩洛哥', p: 'moluoge' },
    { c: '+377', n: '摩纳哥', p: 'monage' },
    { c: '+258', n: '莫桑比克', p: 'mosangbike' },
    { c: '+52', n: '墨西哥', p: 'moxige' },
    { c: '+264', n: '纳米比亚', p: 'namibiya' },
    { c: '+27', n: '南非', p: 'nanfei' },
    { c: '+672', n: '南极洲', p: 'nanjizhou' },
    { c: '+674', n: '瑙鲁', p: 'naolu' },
    { c: '+977', n: '尼泊尔', p: 'niboer' },
    { c: '+505', n: '尼加拉瓜', p: 'nijialagua' },
    { c: '+227', n: '尼日尔', p: 'nirier' },
    { c: '+234', n: '尼日利亚', p: 'niriliya' },
    { c: '+683', n: '纽埃', p: 'niuai' },
    { c: '+47', n: '挪威', p: 'nuowei' },
    { c: '+680', n: '帕劳', p: 'palao' },
    { c: '+870', n: '皮特凯恩群岛', p: 'pitekaienqundao' },
    { c: '+351', n: '葡萄牙', p: 'putaoya' },
    { c: '+81', n: '日本', p: 'riben' },
    { c: '+46', n: '瑞典', p: 'ruidian' },
    { c: '+41', n: '瑞士', p: 'ruishi' },
    { c: '+503', n: '萨尔瓦多', p: 'saerwaduo' },
    { c: '+381', n: '塞尔维亚', p: 'saierweiya' },
    { c: '+232', n: '塞拉利昂', p: 'sailaliang' },
    { c: '+221', n: '塞内加尔', p: 'saineijiaer' },
    { c: '+357', n: '塞浦路斯', p: 'saipulusi' },
    { c: '+685', n: '萨摩亚', p: 'samoya' },
    { c: '+966', n: '沙特阿拉伯', p: 'shatealabo' },
    { c: '+61', n: '圣诞岛', p: 'shengdandao' },
    { c: '+239', n: '圣多美和普林西比', p: 'shengduomeihepulinxibi' },
    { c: '+290', n: '圣赫勒拿', p: 'shenghelena' },
    { c: '+1869', n: '圣基茨和尼维斯', p: 'shengjiciheniweisi' },
    { c: '+1758', n: '圣卢西亚', p: 'shengluxiya' },
    { c: '+1599', n: '圣马丁', p: 'shengmading' },
    { c: '+378', n: '圣马力诺', p: 'shengmalinuo' },
    { c: '+508', n: '圣皮埃尔和密克隆', p: 'shengpiaierhemikelong' },
    {
        c: '+1784',
        n: '圣文森特和格林纳丁斯',
        p: 'shengwensentehegelinnadingsi'
    },
    { c: '+94', n: '斯里兰卡', p: 'sililanka' },
    { c: '+421', n: '斯洛伐克', p: 'siluofake' },
    { c: '+386', n: '斯洛文尼亚', p: 'siluowenniya' },
    { c: '+268', n: '斯威士兰', p: 'siweishilan' },
    { c: '+249', n: '苏丹', p: 'sudan' },
    { c: '+677', n: '所罗门群岛', p: 'suoluomenqundao' },
    { c: '+252', n: '索马里', p: 'suomali' },
    { c: '+66', n: '泰国', p: 'taiguo' },
    { c: '+992', n: '塔吉克斯坦', p: 'tajikesitan' },
    { c: '+676', n: '汤加', p: 'tangjia' },
    { c: '+255', n: '坦桑尼亚', p: 'tansangniya' },
    { c: '+1649', n: '特克斯和凯科斯群岛', p: 'tekesihekaikesiqundao' },
    { c: '+1868', n: '特立尼达和多巴哥', p: 'telinidaheduobage' },
    { c: '+90', n: '土耳其', p: 'tuerqi' },
    { c: '+993', n: '土库曼斯坦', p: 'tukumansitan' },
    { c: '+216', n: '突尼斯', p: 'tunisi' },
    { c: '+690', n: '托克劳', p: 'tuokelao' },
    { c: '+688', n: '图瓦卢', p: 'tuwalu' },
    { c: '+681', n: '瓦利斯和富图纳', p: 'walisihefutuna' },
    { c: '+678', n: '瓦努阿图', p: 'wanuatu' },
    { c: '+502', n: '危地马拉', p: 'weidimala' },
    { c: '+58', n: '委内瑞拉', p: 'weineiruila' },
    { c: '+673', n: '文莱', p: 'wenlai' },
    { c: '+256', n: '乌干达', p: 'wuganda' },
    { c: '+380', n: '乌克兰', p: 'wukelan' },
    { c: '+598', n: '乌拉圭', p: 'wulagui' },
    { c: '+998', n: '乌兹别克斯坦', p: 'wuzibiekesitan' },
    { c: '+34', n: '西班牙', p: 'xibanya' },
    { c: '+30', n: '希腊', p: 'xila' },
    { c: '+65', n: '新加坡', p: 'xinjiapo' },
    { c: '+687', n: '新喀里多尼亚', p: 'xinkaliduoniya' },
    { c: '+64', n: '新西兰', p: 'xinxilan' },
    { c: '+36', n: '匈牙利', p: 'xiongyali' },
    { c: '+963', n: '叙利亚', p: 'xuliya' },
    { c: '+1876', n: '牙买加', p: 'yamaijia' },
    { c: '+374', n: '亚美尼亚', p: 'yameiniya' },
    { c: '+967', n: '也门', p: 'yemen' },
    { c: '+39', n: '意大利', p: 'yidali' },
    { c: '+964', n: '伊拉克', p: 'yilake' },
    { c: '+98', n: '伊朗', p: 'yilang' },
    { c: '+91', n: '印度', p: 'yindu' },
    { c: '+62', n: '印尼', p: 'yindunixiya' },
    { c: '+44', n: '英国', p: 'yingguo' },
    { c: '+1284', n: '英属维尔京群岛', p: 'yingshuweijingqundao' },
    { c: '+972', n: '以色列', p: 'yiselie' },
    { c: '+962', n: '约旦', p: 'yuedan' },
    { c: '+84', n: '越南', p: 'yuenan' },
    { c: '+260', n: '赞比亚', p: 'zanbiya' },
    { c: '+235', n: '乍得', p: 'zhade' },
    { c: '+350', n: '直布罗陀', p: 'zhibuluotuo' },
    { c: '+56', n: '智利', p: 'zhili' },
    { c: '+236', n: '中非', p: 'zhongfeigongheguo' }
];

const countrys = {
    1: {
        n: '中国',
        11: {
            n: '北京',
            1: { n: '东城' },
            2: { n: '西城' },
            5: { n: '朝阳' },
            6: { n: '丰台' },
            7: { n: '石景山' },
            8: { n: '海淀' },
            9: { n: '门头沟' },
            11: { n: '房山' },
            12: { n: '通州' },
            13: { n: '顺义' },
            21: { n: '昌平' },
            24: { n: '大兴' },
            26: { n: '平谷' },
            27: { n: '怀柔' },
            28: { n: '密云' },
            29: { n: '延庆' }
        },
        12: {
            n: '天津',
            1: { n: '和平' },
            2: { n: '河东' },
            3: { n: '河西' },
            4: { n: '南开' },
            5: { n: '河北' },
            6: { n: '红桥' },
            26: { n: '滨海新区' },
            10: { n: '东丽' },
            11: { n: '西青' },
            12: { n: '津南' },
            13: { n: '北辰' },
            21: { n: '宁河' },
            22: { n: '武清' },
            23: { n: '静海' },
            24: { n: '宝坻' },
            25: { n: '蓟县' }
        },
        13: {
            n: '河北',
            1: { n: '石家庄' },
            2: { n: '唐山' },
            3: { n: '秦皇岛' },
            4: { n: '邯郸' },
            5: { n: '邢台' },
            6: { n: '保定' },
            7: { n: '张家口' },
            8: { n: '承德' },
            9: { n: '沧州' },
            10: { n: '廊坊' },
            11: { n: '衡水' }
        },
        14: {
            n: '山西',
            1: { n: '太原' },
            2: { n: '大同' },
            3: { n: '阳泉' },
            4: { n: '长治' },
            5: { n: '晋城' },
            6: { n: '朔州' },
            7: { n: '晋中' },
            8: { n: '运城' },
            9: { n: '忻州' },
            10: { n: '临汾' },
            11: { n: '吕梁' }
        },
        15: {
            n: '内蒙古',
            1: { n: '呼和浩特' },
            2: { n: '包头' },
            3: { n: '乌海' },
            4: { n: '赤峰' },
            5: { n: '通辽' },
            6: { n: '鄂尔多斯' },
            7: { n: '呼伦贝尔' },
            8: { n: '巴彦淖尔' },
            9: { n: '乌兰察布' },
            22: { n: '兴安' },
            25: { n: '锡林郭勒' },
            29: { n: '阿拉善' }
        },
        21: {
            n: '辽宁',
            1: { n: '沈阳' },
            2: { n: '大连' },
            3: { n: '鞍山' },
            4: { n: '抚顺' },
            5: { n: '本溪' },
            6: { n: '丹东' },
            7: { n: '锦州' },
            8: { n: '营口' },
            9: { n: '阜新' },
            10: { n: '辽阳' },
            11: { n: '盘锦' },
            12: { n: '铁岭' },
            13: { n: '朝阳' },
            14: { n: '葫芦岛' }
        },
        22: {
            n: '吉林',
            1: { n: '长春' },
            2: { n: '吉林' },
            3: { n: '四平' },
            4: { n: '辽源' },
            5: { n: '通化' },
            6: { n: '白山' },
            7: { n: '松原' },
            8: { n: '白城' },
            24: { n: '延边' }
        },
        23: {
            n: '黑龙江',
            1: { n: '哈尔滨' },
            2: { n: '齐齐哈尔' },
            3: { n: '鸡西' },
            4: { n: '鹤岗' },
            5: { n: '双鸭山' },
            6: { n: '大庆' },
            7: { n: '伊春' },
            8: { n: '佳木斯' },
            9: { n: '七台河' },
            10: { n: '牡丹江' },
            11: { n: '黑河' },
            12: { n: '绥化' },
            27: { n: '大兴安岭' }
        },
        31: {
            n: '上海',
            1: { n: '黄浦' },
            3: { n: '卢湾' },
            4: { n: '徐汇' },
            5: { n: '长宁' },
            6: { n: '静安' },
            7: { n: '普陀' },
            8: { n: '闸北' },
            9: { n: '虹口' },
            11: { n: '杨浦' },
            12: { n: '闵行' },
            13: { n: '宝山' },
            14: { n: '嘉定' },
            15: { n: '浦东新' },
            16: { n: '金山' },
            17: { n: '松江' },
            26: { n: '奉贤' },
            29: { n: '青浦' },
            30: { n: '崇明' }
        },
        32: {
            n: '江苏',
            1: { n: '南京' },
            2: { n: '无锡' },
            3: { n: '徐州' },
            4: { n: '常州' },
            5: { n: '苏州' },
            6: { n: '南通' },
            7: { n: '连云港' },
            8: { n: '淮安' },
            9: { n: '盐城' },
            10: { n: '扬州' },
            11: { n: '镇江' },
            12: { n: '泰州' },
            13: { n: '宿迁' }
        },
        33: {
            n: '浙江',
            1: { n: '杭州' },
            2: { n: '宁波' },
            3: { n: '温州' },
            4: { n: '嘉兴' },
            5: { n: '湖州' },
            6: { n: '绍兴' },
            7: { n: '金华' },
            8: { n: '衢州' },
            9: { n: '舟山' },
            10: { n: '台州' },
            11: { n: '丽水' }
        },
        34: {
            n: '安徽',
            1: { n: '合肥' },
            2: { n: '芜湖' },
            3: { n: '蚌埠' },
            4: { n: '淮南' },
            5: { n: '马鞍山' },
            6: { n: '淮北' },
            7: { n: '铜陵' },
            8: { n: '安庆' },
            10: { n: '黄山' },
            11: { n: '滁州' },
            12: { n: '阜阳' },
            13: { n: '宿州' },
            15: { n: '六安' },
            16: { n: '亳州' },
            17: { n: '池州' },
            18: { n: '宣城' }
        },
        35: {
            n: '福建',
            1: { n: '福州' },
            2: { n: '厦门' },
            3: { n: '莆田' },
            4: { n: '三明' },
            5: { n: '泉州' },
            6: { n: '漳州' },
            7: { n: '南平' },
            8: { n: '龙岩' },
            9: { n: '宁德' }
        },
        36: {
            n: '江西',
            1: { n: '南昌' },
            2: { n: '景德镇' },
            3: { n: '萍乡' },
            4: { n: '九江' },
            5: { n: '新余' },
            6: { n: '鹰潭' },
            7: { n: '赣州' },
            8: { n: '吉安' },
            9: { n: '宜春' },
            10: { n: '抚州' },
            11: { n: '上饶' }
        },
        37: {
            n: '山东',
            1: { n: '济南' },
            2: { n: '青岛' },
            3: { n: '淄博' },
            4: { n: '枣庄' },
            5: { n: '东营' },
            6: { n: '烟台' },
            7: { n: '潍坊' },
            8: { n: '济宁' },
            9: { n: '泰安' },
            10: { n: '威海' },
            11: { n: '日照' },
            12: { n: '莱芜' },
            13: { n: '临沂' },
            14: { n: '德州' },
            15: { n: '聊城' },
            16: { n: '滨州' },
            17: { n: '菏泽' }
        },
        41: {
            n: '河南',
            1: { n: '郑州' },
            2: { n: '开封' },
            3: { n: '洛阳' },
            4: { n: '平顶山' },
            5: { n: '安阳' },
            6: { n: '鹤壁' },
            7: { n: '新乡' },
            8: { n: '焦作' },
            9: { n: '濮阳' },
            10: { n: '许昌' },
            11: { n: '漯河' },
            12: { n: '三门峡' },
            13: { n: '南阳' },
            14: { n: '商丘' },
            15: { n: '信阳' },
            16: { n: '周口' },
            17: { n: '驻马店' },
            18: { n: '济源' }
        },
        42: {
            n: '湖北',
            1: { n: '武汉' },
            2: { n: '黄石' },
            3: { n: '十堰' },
            5: { n: '宜昌' },
            6: { n: '襄阳' },
            7: { n: '鄂州' },
            8: { n: '荆门' },
            9: { n: '孝感' },
            10: { n: '荆州' },
            11: { n: '黄冈' },
            12: { n: '咸宁' },
            13: { n: '随州' },
            28: { n: '恩施' },
            94: { n: '仙桃' },
            95: { n: '潜江' },
            96: { n: '天门' },
            A21: { n: '神农架' }
        },
        43: {
            n: '湖南',
            1: { n: '长沙' },
            2: { n: '株洲' },
            3: { n: '湘潭' },
            4: { n: '衡阳' },
            5: { n: '邵阳' },
            6: { n: '岳阳' },
            7: { n: '常德' },
            8: { n: '张家界' },
            9: { n: '益阳' },
            10: { n: '郴州' },
            11: { n: '永州' },
            12: { n: '怀化' },
            13: { n: '娄底' },
            31: { n: '湘西' }
        },
        44: {
            n: '广东',
            1: { n: '广州' },
            2: { n: '韶关' },
            3: { n: '深圳' },
            4: { n: '珠海' },
            5: { n: '汕头' },
            6: { n: '佛山' },
            7: { n: '江门' },
            8: { n: '湛江' },
            9: { n: '茂名' },
            12: { n: '肇庆' },
            13: { n: '惠州' },
            14: { n: '梅州' },
            15: { n: '汕尾' },
            16: { n: '河源' },
            17: { n: '阳江' },
            18: { n: '清远' },
            19: { n: '东莞' },
            20: { n: '中山' },
            51: { n: '潮州' },
            52: { n: '揭阳' },
            53: { n: '云浮' }
        },
        45: {
            n: '广西',
            1: { n: '南宁' },
            2: { n: '柳州' },
            3: { n: '桂林' },
            4: { n: '梧州' },
            5: { n: '北海' },
            6: { n: '防城港' },
            7: { n: '钦州' },
            8: { n: '贵港' },
            9: { n: '玉林' },
            10: { n: '百色' },
            11: { n: '贺州' },
            12: { n: '河池' },
            13: { n: '来宾' },
            14: { n: '崇左' }
        },
        46: {
            n: '海南',
            1: { n: '海口' },
            2: { n: '三亚' },
            3: { n: '三沙' },
            91: { n: '五指山' },
            92: { n: '琼海' },
            93: { n: '儋州' },
            95: { n: '文昌' },
            96: { n: '万宁' },
            97: { n: '东方' },
            A25: { n: '定安' },
            A26: { n: '屯昌' },
            A27: { n: '澄迈' },
            A28: { n: '临高' },
            A30: { n: '白沙' },
            A31: { n: '昌江' },
            A33: { n: '乐东' },
            A34: { n: '陵水' },
            A35: { n: '保亭' },
            A36: { n: '琼中' }
        },
        50: {
            n: '重庆',
            1: { n: '万州' },
            2: { n: '涪陵' },
            3: { n: '渝中' },
            4: { n: '大渡口' },
            5: { n: '江北' },
            6: { n: '沙坪坝' },
            7: { n: '九龙坡' },
            8: { n: '南岸' },
            9: { n: '北碚' },
            85: { n: '两江新区' },
            10: { n: '万盛' },
            11: { n: '双桥' },
            12: { n: '渝北' },
            13: { n: '巴南' },
            21: { n: '长寿' },
            22: { n: '綦江' },
            23: { n: '潼南' },
            24: { n: '铜梁' },
            25: { n: '大足' },
            26: { n: '荣昌' },
            27: { n: '璧山' },
            28: { n: '梁平' },
            29: { n: '城口' },
            30: { n: '丰都' },
            31: { n: '垫江' },
            32: { n: '武隆' },
            33: { n: '忠县' },
            34: { n: '开县' },
            35: { n: '云阳' },
            36: { n: '奉节' },
            37: { n: '巫山' },
            38: { n: '巫溪' },
            39: { n: '黔江' },
            40: { n: '石柱' },
            41: { n: '秀山' },
            42: { n: '酉阳' },
            43: { n: '彭水' },
            81: { n: '江津' },
            82: { n: '合川' },
            83: { n: '永川' },
            84: { n: '南川' }
        },
        51: {
            n: '四川',
            1: { n: '成都' },
            3: { n: '自贡' },
            4: { n: '攀枝花' },
            5: { n: '泸州' },
            6: { n: '德阳' },
            7: { n: '绵阳' },
            8: { n: '广元' },
            9: { n: '遂宁' },
            10: { n: '内江' },
            11: { n: '乐山' },
            13: { n: '南充' },
            14: { n: '眉山' },
            15: { n: '宜宾' },
            16: { n: '广安' },
            17: { n: '达州' },
            18: { n: '雅安' },
            19: { n: '巴中' },
            20: { n: '资阳' },
            32: { n: '阿坝' },
            33: { n: '甘孜' },
            34: { n: '凉山' }
        },
        52: {
            n: '贵州',
            1: { n: '贵阳' },
            2: { n: '六盘水' },
            3: { n: '遵义' },
            4: { n: '安顺' },
            22: { n: '铜仁' },
            23: { n: '黔西南' },
            24: { n: '毕节' },
            26: { n: '黔东南' },
            27: { n: '黔南' }
        },
        53: {
            n: '云南',
            1: { n: '昆明' },
            3: { n: '曲靖' },
            4: { n: '玉溪' },
            5: { n: '保山' },
            6: { n: '昭通' },
            7: { n: '丽江' },
            8: { n: '普洱' },
            9: { n: '临沧' },
            23: { n: '楚雄' },
            25: { n: '红河' },
            26: { n: '文山' },
            28: { n: '西双版纳' },
            29: { n: '大理' },
            31: { n: '德宏' },
            33: { n: '怒江' },
            34: { n: '迪庆' }
        },
        54: {
            n: '西藏',
            1: { n: '拉萨' },
            21: { n: '昌都' },
            22: { n: '山南' },
            23: { n: '日喀则' },
            24: { n: '那曲' },
            25: { n: '阿里' },
            26: { n: '林芝' }
        },
        61: {
            n: '陕西',
            1: { n: '西安' },
            2: { n: '铜川' },
            3: { n: '宝鸡' },
            4: { n: '咸阳' },
            5: { n: '渭南' },
            6: { n: '延安' },
            7: { n: '汉中' },
            8: { n: '榆林' },
            9: { n: '安康' },
            10: { n: '商洛' }
        },
        62: {
            n: '甘肃',
            1: { n: '兰州市' },
            2: { n: '嘉峪关' },
            3: { n: '金昌' },
            4: { n: '白银' },
            5: { n: '天水' },
            6: { n: '武威' },
            7: { n: '张掖' },
            8: { n: '平凉' },
            9: { n: '酒泉' },
            10: { n: '庆阳' },
            11: { n: '定西' },
            12: { n: '陇南' },
            29: { n: '临夏' },
            30: { n: '甘南' }
        },
        63: {
            n: '青海',
            1: { n: '西宁' },
            21: { n: '海东' },
            22: { n: '海北' },
            23: { n: '黄南' },
            25: { n: '海南' },
            26: { n: '果洛' },
            27: { n: '玉树' },
            28: { n: '海西' }
        },
        64: {
            n: '宁夏',
            1: { n: '银川' },
            2: { n: '石嘴山' },
            3: { n: '吴忠' },
            4: { n: '固原' },
            5: { n: '中卫' }
        },
        65: {
            n: '新疆',
            1: { n: '乌鲁木齐' },
            2: { n: '克拉玛依' },
            21: { n: '吐鲁番' },
            22: { n: '哈密' },
            23: { n: '昌吉' },
            27: { n: '博尔塔拉' },
            28: { n: '巴音郭楞' },
            29: { n: '阿克苏' },
            30: { n: '克孜勒苏' },
            31: { n: '喀什' },
            32: { n: '和田' },
            40: { n: '伊犁' },
            42: { n: '塔城' },
            43: { n: '阿勒泰' },
            91: { n: '石河子' },
            92: { n: '阿拉尔' },
            93: { n: '图木舒克' },
            94: { n: '五家渠' },
            95: { n: '北屯' }
        },
        71: {
            n: '台湾',
            1: { n: '台北市' },
            2: { n: '高雄市' },
            3: { n: '基隆市' },
            4: { n: '台中市' },
            5: { n: '台南市' },
            6: { n: '新竹市' },
            7: { n: '嘉义市' },
            8: { n: '台北县' },
            9: { n: '宜兰县' },
            10: { n: '桃园县' },
            11: { n: '新竹县' },
            12: { n: '苗栗县' },
            13: { n: '台中县' },
            14: { n: '彰化县' },
            15: { n: '南投县' },
            16: { n: '云林县' },
            17: { n: '嘉义县' },
            18: { n: '台南县' },
            19: { n: '高雄县' },
            20: { n: '屏东县' },
            22: { n: '台东县' },
            23: { n: '花莲县' },
            21: { n: '澎湖县' }
        },
        81: {
            n: '香港',
            HCW: { n: '中西区' },
            HEA: { n: '东区' },
            KKC: { n: '九龙城区' },
            KKT: { n: '观塘区' },
            HSO: { n: '南区' },
            KSS: { n: '深水埗区' },
            KWT: { n: '黄大仙区' },
            HWC: { n: '湾仔区' },
            KYT: { n: '油尖旺区' },
            NIS: { n: '离岛区' },
            NKT: { n: '葵青区' },
            NNO: { n: '北区' },
            NSK: { n: '西贡区' },
            NST: { n: '沙田区' },
            NTM: { n: '屯门区' },
            NTP: { n: '大埔区' },
            NTW: { n: '荃湾区' },
            NYL: { n: '元朗区' }
        },
        82: {
            n: '澳门',
            OLF: { n: '花地玛堂区' },
            ANT: { n: '圣安多尼堂区' },
            CAT: { n: '大堂区' },
            LAW: { n: '望德堂区' },
            LAZ: { n: '风顺堂区' },
            TPA: { n: '氹仔' },
            CLN: { n: '路环' }
        }
    },
    ALB: {
        n: '阿尔巴尼亚',
        0: {
            n: '',
            EL: { n: '爱尔巴桑' },
            DI: { n: '迪勃拉' },
            TR: { n: '地拉那' },
            DR: { n: '都拉斯' },
            VL: { n: '发罗拉' },
            FR: { n: '费里' },
            GJ: { n: '吉诺卡斯特' },
            KO: { n: '科尔察' },
            KU: { n: '库克斯' },
            LE: { n: '莱什' },
            BR: { n: '培拉特' },
            SH: { n: '斯库台' }
        }
    },
    DZA: {
        n: '阿尔及利亚',
        0: {
            n: '',
            ADR: { n: '阿德拉尔' },
            ALG: { n: '阿尔及尔' },
            ADE: { n: '艾因·德夫拉' },
            ATE: { n: '艾因·蒂姆尚特' },
            AAE: { n: '安纳巴' },
            ORA: { n: '奥兰' },
            BAT: { n: '巴特纳' },
            BJA: { n: '贝贾亚' },
            BEC: { n: '贝沙尔' },
            EBA: { n: '贝伊德' },
            BIS: { n: '比斯克拉' },
            BOR: { n: '布尔吉·布阿雷里吉' },
            BLI: { n: '布利达' },
            BOU: { n: '布迈德斯' },
            BOA: { n: '布依拉' },
            TIP: { n: '蒂巴扎' },
            TIS: { n: '蒂斯姆西勒特' },
            GHA: { n: '盖尔达耶' },
            GUE: { n: '盖尔马' },
            KHE: { n: '罕西拉' },
            REL: { n: '赫利赞' },
            JIJ: { n: '吉杰尔' },
            DJE: { n: '杰勒法' },
            CZL: { n: '君士坦丁' },
            LAG: { n: '拉格瓦特' },
            MUA: { n: '马斯卡拉' },
            MED: { n: '麦迪亚' },
            MIL: { n: '密拉' },
            MOS: { n: '莫斯塔加纳姆' },
            MSI: { n: '姆西拉' },
            NAA: { n: '纳阿马' },
            SET: { n: '塞蒂夫' },
            SAI: { n: '赛伊达' },
            SKI: { n: '斯基克达' },
            SAH: { n: '苏克·阿赫拉斯' },
            ETA: { n: '塔里夫' },
            TAM: { n: '塔曼拉塞特' },
            TEB: { n: '特贝萨' },
            TLE: { n: '特莱姆森' },
            IOU: { n: '提济乌祖' },
            TIA: { n: '提亚雷特' },
            TIN: { n: '廷杜夫' },
            EOU: { n: '瓦德' },
            OUA: { n: '瓦尔格拉' },
            OEB: { n: '乌姆布阿基' },
            SBA: { n: '西迪贝勒阿贝斯' },
            CHL: { n: '谢里夫' },
            ILL: { n: '伊利齐' }
        }
    },
    AFG: {
        n: '阿富汗',
        0: {
            n: '',
            HEA: { n: '赫拉特' },
            KBL: { n: '喀布尔' },
            KDH: { n: '坎大哈' },
            MZR: { n: '马扎里沙里夫' }
        }
    },
    ARG: {
        n: '阿根廷',
        0: {
            n: '',
            PRA: { n: '巴拉那' },
            VDM: { n: '别德马' },
            PSS: { n: '波萨达斯' },
            BHI: { n: '布兰卡港' },
            BUE: { n: '布宜诺斯艾利斯' },
            FMA: { n: '福莫萨' },
            JUJ: { n: '胡胡伊' },
            CTC: { n: '卡塔马卡' },
            COR: { n: '科尔多瓦' },
            CNQ: { n: '科连特斯' },
            VLK: { n: '克劳斯城' },
            COC: { n: '肯考迪娅' },
            IRJ: { n: '拉里奥哈' },
            LPG: { n: '拉普拉塔' },
            RES: { n: '雷西斯滕匹亚' },
            RGL: { n: '里奥加耶戈斯' },
            RCU: { n: '里奥夸尔托' },
            CRD: { n: '里瓦达维亚海军准将城' },
            ROS: { n: '罗萨里奥' },
            RWO: { n: '罗森' },
            MDQ: { n: '马德普拉塔' },
            MDZ: { n: '门多萨' },
            NQN: { n: '内乌肯' },
            SLA: { n: '萨尔塔' },
            SDE: { n: '圣地亚哥-德尔埃斯特罗' },
            SFN: { n: '圣菲' },
            UAQ: { n: '圣胡安' },
            AFA: { n: '圣拉斐尔' },
            LUQ: { n: '圣路易斯' },
            RSA: { n: '圣罗莎' },
            SMC: { n: '圣米格尔-德图库曼' },
            SNS: { n: '圣尼古拉斯' },
            REL: { n: '特雷利乌' },
            USH: { n: '乌斯怀亚' }
        }
    },
    ARE: {
        n: '阿拉伯联合酋长国',
        0: {
            n: '',
            AZ: { n: '阿布扎比' },
            AL: { n: '艾因' },
            DU: { n: '迪拜' },
            SH: { n: '沙迦' }
        }
    },
    ABW: { n: '阿鲁巴', 0: { n: '', 0: { n: '' } } },
    OMN: {
        n: '阿曼',
        0: {
            n: '',
            BA: { n: '巴提奈地区' },
            ZA: { n: '达希莱地区' },
            SH: { n: '东部地区' },
            MA: { n: '马斯喀特省' },
            MU: { n: '穆桑达姆省' },
            DA: { n: '内地地区' },
            WU: { n: '中部地区' },
            ZU: { n: '佐法尔省' }
        }
    },
    AZE: {
        n: '阿塞拜疆',
        0: {
            n: '',
            ABS: { n: '阿布歇隆' },
            XAC: { n: '哈奇马斯' },
            KAL: { n: '卡尔巴卡尔' },
            QAZ: { n: '卡扎赫' },
            LAN: { n: '连科兰' },
            MQA: { n: '密尔-卡拉巴赫' },
            MSA: { n: '穆甘-萨连' },
            NQA: { n: '纳戈尔诺－卡拉巴赫' },
            NX: { n: '纳希切万' },
            PRI: { n: '普利亚拉克斯' },
            SA: { n: '舍基' },
            SMC: { n: '苏姆盖特' },
            SIR: { n: '锡尔万' },
            GA: { n: '占贾' }
        }
    },
    ASC: { n: '阿森松岛', 0: { n: '', 0: { n: '' } } },
    EGY: {
        n: '埃及',
        0: {
            n: '',
            ASW: { n: '阿斯旺' },
            GBY: { n: '古尔代盖' },
            CAI: { n: '开罗' },
            SKH: { n: '苏布拉开马' },
            ALY: { n: '亚历山大' }
        }
    },
    ETH: {
        n: '埃塞俄比亚',
        0: {
            n: '',
            AF: { n: '阿法尔' },
            AH: { n: '阿姆哈拉' },
            OR: { n: '奥罗米亚' },
            BG: { n: '宾香古尔' },
            DD: { n: '德雷达瓦' },
            GB: { n: '甘贝拉各族' },
            HR: { n: '哈勒里民族' },
            SN: { n: '南方各族' },
            SM: { n: '索马里' },
            TG: { n: '提格雷' },
            AA: { n: '亚的斯亚贝巴' }
        }
    },
    IRL: {
        n: '爱尔兰',
        0: {
            n: '',
            OF: { n: '奥法利' },
            TP: { n: '蒂珀雷里' },
            DB: { n: '都柏林' },
            DG: { n: '多内加尔' },
            GW: { n: '戈尔韦' },
            KD: { n: '基尔代尔' },
            KK: { n: '基尔肯尼' },
            CV: { n: '卡范' },
            CW: { n: '卡洛' },
            KR: { n: '凯里' },
            CK: { n: '科克' },
            CL: { n: '克莱尔' },
            LF: { n: '朗福德' },
            LT: { n: '劳斯' },
            LA: { n: '崂斯' },
            LM: { n: '利默里克' },
            LR: { n: '利特里姆' },
            RC: { n: '罗斯康芒' },
            MY: { n: '梅奥' },
            MT: { n: '米斯' },
            MG: { n: '莫内根' },
            SL: { n: '斯莱戈' },
            WK: { n: '威克洛' },
            WX: { n: '韦克斯福德' },
            WF: { n: '沃特福德' },
            WM: { n: '西米斯' }
        }
    },
    EST: {
        n: '爱沙尼亚',
        0: {
            n: '',
            65: { n: '贝尔瓦' },
            37: { n: '哈留' },
            70: { n: '拉普拉' },
            57: { n: '里亚内' },
            67: { n: '帕尔努' },
            74: { n: '萨雷' },
            78: { n: '塔尔图' },
            82: { n: '瓦尔加' },
            84: { n: '维良地' },
            59: { n: '维鲁' },
            86: { n: '沃鲁' },
            39: { n: '希尤' },
            51: { n: '耶尔韦' },
            49: { n: '耶盖瓦' },
            44: { n: '依达－维鲁' }
        }
    },
    AND: {
        n: '安道尔',
        0: {
            n: '',
            7: { n: '安道尔城' },
            5: { n: '奥尔迪诺' },
            3: { n: '恩坎普' },
            2: { n: '卡尼略' },
            8: { n: '莱塞斯卡尔德－恩戈尔达' },
            4: { n: '马萨纳' },
            6: { n: '圣胡利娅－德洛里亚' }
        }
    },
    AGO: {
        n: '安哥拉',
        0: {
            n: '',
            CNO: { n: '北宽扎' },
            LNO: { n: '北隆达' },
            BGO: { n: '本戈' },
            BGU: { n: '本格拉' },
            BIE: { n: '比耶' },
            CAB: { n: '卡宾达' },
            CNN: { n: '库内内' },
            CCU: { n: '宽多库邦戈' },
            LUA: { n: '罗安达' },
            MAL: { n: '马兰热' },
            MOX: { n: '莫希科' },
            NAM: { n: '纳米贝' },
            CUS: { n: '南宽扎' },
            LSU: { n: '南隆达' },
            HUA: { n: '万博' },
            HUI: { n: '威拉' },
            UIG: { n: '威热' },
            ZAI: { n: '扎伊尔' }
        }
    },
    AIA: { n: '安圭拉', 0: { n: '', 0: { n: '' } } },
    ATG: { n: '安提瓜岛和巴布达', 0: { n: '', 0: { n: '' } } },
    AUS: {
        n: '澳大利亚',
        NT: { n: '北部地区', PAL: { n: '北帕默斯顿' }, DRW: { n: '达尔文' } },
        ACT: { n: '堪培拉', CBR: { n: '堪培拉' } },
        QLD: {
            n: '昆士兰',
            BNE: { n: '布里斯班' },
            OOL: { n: '黄金海岸' },
            CNS: { n: '凯恩斯' },
            CUD: { n: '日光海岸' },
            TSV: { n: '汤斯维尔' },
            TWB: { n: '图文巴' }
        },
        SA: {
            n: '南澳大利亚',
            ADL: { n: '阿德莱德' },
            PUG: { n: '奥古斯塔港' },
            MGB: { n: '甘比亚山' },
            WAY: { n: '怀阿拉' },
            PLO: { n: '林肯港' },
            MYB: { n: '默里布里奇' },
            PPI: { n: '皮里港' },
            VHA: { n: '维克托港' }
        },
        TAS: {
            n: '塔斯马尼亚',
            BWT: { n: '伯尼港' },
            DPO: { n: '德文波特' },
            HBA: { n: '霍巴特' },
            LST: { n: '朗塞斯顿' }
        },
        VIC: { n: '维多利亚', GEX: { n: '吉朗' }, MEL: { n: '墨尔本' } },
        WA: {
            n: '西澳大利亚',
            ALH: { n: '奥尔巴尼' },
            BUY: { n: '班伯里' },
            FRE: { n: '弗里曼特尔港' },
            GET: { n: '杰拉尔顿' },
            KGI: { n: '卡尔古利' },
            MDU: { n: '曼哲拉' },
            PER: { n: '珀斯' }
        },
        NSW: {
            n: '新南威尔士',
            NTL: { n: '纽卡斯尔' },
            WOL: { n: '伍伦贡' },
            HBS: { n: '悉尼' }
        }
    },
    AUT: {
        n: '奥地利',
        0: {
            n: '',
            BUR: { n: '布尔根兰' },
            TYR: { n: '蒂罗尔' },
            VOR: { n: '福拉尔贝格' },
            CAT: { n: '克恩顿' },
            SZG: { n: '萨尔茨堡' },
            UAU: { n: '上奥地利' },
            STY: { n: '施蒂利亚' },
            VDD: { n: '维也纳' },
            LAU: { n: '下奥地利' }
        }
    },
    ALA: { n: '奥兰群岛', 0: { n: '', 0: { n: '' } } },
    BRB: { n: '巴巴多斯岛', 0: { n: '', 0: { n: '' } } },
    PNG: {
        n: '巴布亚新几内亚',
        0: {
            n: '',
            NO: { n: '北部' },
            BV: { n: '布干维尔' },
            EH: { n: '东部高地' },
            ES: { n: '东塞皮克' },
            EB: { n: '东新不列颠' },
            EN: { n: '恩加' },
            GU: { n: '海湾' },
            MD: { n: '马当' },
            MN: { n: '马努斯' },
            MB: { n: '米尔恩湾' },
            NC: { n: '莫尔兹比港' },
            MR: { n: '莫罗贝' },
            SH: { n: '南部高地' },
            SI: { n: '钦布' },
            SA: { n: '桑道恩' },
            WE: { n: '西部' },
            WH: { n: '西部高地' },
            WB: { n: '西新不列颠' },
            NI: { n: '新爱尔兰' }
        }
    },
    BHS: { n: '巴哈马', 0: { n: '', 0: { n: '' } } },
    PAK: {
        n: '巴基斯坦',
        0: {
            n: '',
            PEW: { n: '白沙瓦' },
            LYP: { n: '费萨拉巴德' },
            GUJ: { n: '故吉软瓦拉' },
            HDD: { n: '海德拉巴' },
            KCT: { n: '卡拉奇' },
            LHE: { n: '拉合尔' },
            RWP: { n: '拉瓦尔品第' },
            MUX: { n: '木尔坦' },
            ISB: { n: '伊斯兰堡' }
        }
    },
    PRY: {
        n: '巴拉圭',
        0: {
            n: '',
            AM: { n: '阿曼拜' },
            PH: { n: '阿耶斯总统省' },
            PA: { n: '巴拉瓜里' },
            BO: { n: '博克龙' },
            GU: { n: '瓜伊拉' },
            CG: { n: '卡瓜苏' },
            CN: { n: '卡嫩迪尤' },
            CZ: { n: '卡萨帕' },
            CC: { n: '康塞普西翁' },
            CD: { n: '科迪勒拉' },
            MI: { n: '米西奥内斯' },
            NE: { n: '涅恩布库' },
            AG: { n: '上巴拉圭' },
            AN: { n: '上巴拉那' },
            SP: { n: '圣佩德罗' },
            AS: { n: '亚松森特别区' },
            IT: { n: '伊塔普亚' },
            CE: { n: '中央' }
        }
    },
    PSE: {
        n: '巴勒斯坦',
        0: { n: '', GZ: { n: '加沙地带' }, WE: { n: '西岸' } }
    },
    BHR: {
        n: '巴林',
        0: {
            n: '',
            5: { n: '北部' },
            1: { n: '哈德' },
            12: { n: '哈马德' },
            9: { n: '里法' },
            3: { n: '麦纳麦' },
            2: { n: '穆哈拉格' },
            10: { n: '西部' },
            8: { n: '伊萨城' },
            7: { n: '中部' }
        }
    },
    PAN: { n: '巴拿马', 0: { n: '', 0: { n: '' } } },
    BRA: {
        n: '巴西',
        0: {
            n: '',
            AC: { n: '阿克里' },
            AL: { n: '阿拉戈斯' },
            AP: { n: '阿马帕' },
            PR: { n: '巴拉那' },
            BSB: { n: '巴西利亚' },
            BA: { n: '巴伊亚' },
            RN: { n: '北里奥格兰德' },
            PE: { n: '伯南布哥' },
            GO: { n: '戈亚斯' },
            RO: { n: '朗多尼亚' },
            RJ: { n: '里约热内卢' },
            RR: { n: '罗赖马' },
            MA: { n: '马拉尼昂' },
            MT: { n: '马托格罗索' },
            MG: { n: '米纳斯吉拉斯' },
            RS: { n: '南里奥格兰德' },
            MS: { n: '南马托格罗索' },
            PA: { n: '帕拉' },
            PB: { n: '帕拉伊巴' },
            PI: { n: '皮奥伊' },
            CE: { n: '塞阿拉' },
            SE: { n: '塞尔希培' },
            ES: { n: '圣埃斯皮里图' },
            SP: { n: '圣保罗' },
            SC: { n: '圣卡塔琳娜' },
            TO: { n: '托坎廷斯' },
            AM: { n: '亚马孙' }
        }
    },
    BLR: {
        n: '白俄罗斯',
        0: {
            n: '',
            BR: { n: '布列斯特' },
            HO: { n: '戈梅利' },
            HR: { n: '格罗德诺' },
            MI: { n: '明斯克市' },
            MA: { n: '莫吉廖夫' },
            VI: { n: '维捷布斯克' }
        }
    },
    BMU: { n: '百慕大', 0: { n: '', 0: { n: '' } } },
    BGR: {
        n: '保加利亚',
        0: {
            n: '',
            BOJ: { n: '布尔加斯' },
            KHO: { n: '卡斯科伏' },
            ROU: { n: '鲁塞' },
            LVP: { n: '洛维奇' },
            OZA: { n: '蒙塔纳' },
            PDV: { n: '普罗夫迪夫' },
            SOF: { n: '索非亚' },
            GSO: { n: '索非亚市' },
            VAR: { n: '瓦尔纳' }
        }
    },
    MNP: { n: '北马里亚纳群岛', 0: { n: '', 0: { n: '' } } },
    BEN: {
        n: '贝宁',
        0: {
            n: '',
            AL: { n: '阿黎博里' },
            AK: { n: '阿塔科拉' },
            LI: { n: '滨海' },
            BOH: { n: '波希康市' },
            BO: { n: '博尔古' },
            AQ: { n: '大西洋' },
            PL: { n: '高原' },
            KO: { n: '库福' },
            MO: { n: '莫诺' },
            CO: { n: '丘陵' },
            OU: { n: '韦梅' },
            DO: { n: '峡谷' },
            ZO: { n: '祖' }
        }
    },
    BEL: {
        n: '比利时',
        0: {
            n: '',
            WHT: { n: '埃诺' },
            VAN: { n: '安特卫普' },
            WBR: { n: '布拉班特-瓦隆' },
            BRU: { n: '布鲁塞尔' },
            VOV: { n: '东佛兰德' },
            VBR: { n: '佛兰芒-布拉班特' },
            WLG: { n: '列日' },
            VLI: { n: '林堡' },
            WLX: { n: '卢森堡' },
            WNA: { n: '那慕尔' },
            VWV: { n: '西佛兰德' }
        }
    },
    ISL: { n: '冰岛', 0: { n: '', 0: { n: '' } } },
    PRI: { n: '波多黎各', 0: { n: '', 0: { n: '' } } },
    POL: {
        n: '波兰',
        0: {
            n: '',
            ELB: { n: '埃尔布隆格' },
            OLS: { n: '奥尔什丁' },
            OSS: { n: '奥斯特罗文卡' },
            BZG: { n: '比得哥什' },
            PIO: { n: '彼得库夫' },
            BYT: { n: '比托姆' },
            BAP: { n: '比亚瓦波德拉斯卡' },
            BIA: { n: '比亚维斯托克' },
            OPO: { n: '波莱' },
            POZ: { n: '波兹南' },
            DAB: { n: '达布罗瓦戈尼察' },
            GOW: { n: '大波兰地区戈茹夫' },
            WRO: { n: '弗罗茨瓦夫' },
            WLO: { n: '弗沃茨瓦韦克' },
            GDN: { n: '格但斯克' },
            GDY: { n: '格丁尼亚' },
            GWC: { n: '格利维采' },
            GRU: { n: '格鲁琼兹' },
            CHO: { n: '海乌姆' },
            WAW: { n: '华沙' },
            CHZ: { n: '霍茹夫' },
            KAL: { n: '卡利什' },
            KTW: { n: '卡托维兹' },
            KLC: { n: '凯尔采' },
            KON: { n: '科宁' },
            OSZ: { n: '科沙林' },
            KRK: { n: '克拉科夫' },
            KRO: { n: '克罗斯诺' },
            RDM: { n: '拉多姆' },
            LEG: { n: '莱格尼察' },
            LEZ: { n: '莱什诺' },
            LUL: { n: '卢布林' },
            RDS: { n: '鲁达' },
            LOD: { n: '罗兹' },
            IEG: { n: '绿山城' },
            MYL: { n: '米什洛维采' },
            PIL: { n: '皮瓦' },
            PRZ: { n: '普热梅希尔' },
            PLO: { n: '普沃茨克' },
            CIE: { n: '切哈努夫' },
            RZE: { n: '热舒夫' },
            SZZ: { n: '什切青' },
            SKI: { n: '斯凯尔涅维采' },
            SLP: { n: '斯武普斯克' },
            SWL: { n: '苏瓦乌基' },
            SOP: { n: '索波特' },
            SWC: { n: '索斯诺维茨' },
            TAR: { n: '塔尔努夫' },
            QEP: { n: '塔尔诺布热格' },
            TYY: { n: '特切' },
            TOR: { n: '托伦' },
            WZH: { n: '瓦乌布日赫' },
            QOY: { n: '沃姆扎' },
            SOW: { n: '希米亚诺维采' },
            SWI: { n: '希维诺乌伊希切' },
            SWT: { n: '希维托赫洛维采' },
            SDC: { n: '谢德尔采' },
            SIR: { n: '谢拉兹' },
            NOW: { n: '新松奇' },
            JAW: { n: '雅沃兹诺' },
            JEG: { n: '耶莱尼亚古拉' },
            ZAB: { n: '扎布热' },
            ZAM: { n: '扎莫希奇' }
        }
    },
    BOL: {
        n: '玻利维亚',
        0: {
            n: '',
            ALT: { n: '奥尔托' },
            ORU: { n: '奥鲁罗' },
            BEN: { n: '贝尼' },
            POI: { n: '波多西' },
            QUI: { n: '基拉科洛' },
            CBB: { n: '科恰班巴' },
            LPB: { n: '拉巴斯' },
            PAN: { n: '潘多' },
            CHU: { n: '丘基萨卡' },
            SAC: { n: '萨卡巴' },
            SRZ: { n: '圣克鲁斯' },
            TJA: { n: '塔里哈' }
        }
    },
    BIH: {
        n: '波斯尼亚和黑塞哥维那',
        0: {
            n: '',
            FPO: { n: '波萨维纳' },
            FBP: { n: '波斯尼亚－波德里涅' },
            FTO: { n: '多米斯拉夫格勒' },
            FHN: { n: '黑塞哥维那－涅雷特瓦' },
            FSA: { n: '萨拉热窝' },
            FTU: { n: '图兹拉－波德里涅' },
            FUS: { n: '乌纳－萨纳' },
            FHB: { n: '西波斯尼亚' },
            FZH: { n: '西黑塞哥维那' },
            FZE: { n: '泽尼察－多博伊' },
            FSB: { n: '中波斯尼亚' }
        }
    },
    BWA: { n: '博茨瓦纳', 0: { n: '', 0: { n: '' } } },
    BLZ: {
        n: '伯利兹',
        0: {
            n: '',
            BZ: { n: '伯利兹' },
            OW: { n: '橘园' },
            CY: { n: '卡约' },
            CR: { n: '科罗萨尔' },
            SC: { n: '斯坦港' },
            TO: { n: '托莱多' }
        }
    },
    BTN: { n: '不丹', 0: { n: '', 0: { n: '' } } },
    BFA: {
        n: '布基纳法索',
        0: {
            n: '',
            BAL: { n: '巴雷' },
            BAM: { n: '巴姆' },
            BAN: { n: '巴瓦' },
            BAZ: { n: '巴泽加' },
            PON: { n: '波尼' },
            BLG: { n: '布尔古' },
            BOK: { n: '布尔基恩德' },
            BOR: { n: '布古里巴' },
            GAN: { n: '冈祖尔古' },
            GOU: { n: '古尔马' },
            ZIR: { n: '济罗' },
            KAD: { n: '卡焦戈' },
            KEN: { n: '凯内杜古' },
            KOO: { n: '科蒙加里' },
            COM: { n: '科莫埃' },
            KOP: { n: '孔皮恩加' },
            KOS: { n: '孔西' },
            KOL: { n: '库尔佩罗戈' },
            KOW: { n: '库尔维奥戈' },
            KOT: { n: '库里滕加' },
            LER: { n: '雷拉巴' },
            LOR: { n: '罗卢姆' },
            MOU: { n: '穆翁' },
            NAM: { n: '纳门滕加' },
            NAH: { n: '纳乌里' },
            NAY: { n: '纳亚拉' },
            GNA: { n: '尼亚尼亚' },
            NOU: { n: '努姆比埃尔' },
            PAS: { n: '帕索雷' },
            SEN: { n: '塞诺' },
            SAG: { n: '桑吉' },
            SAM: { n: '桑马滕加' },
            SOR: { n: '苏鲁' },
            SOM: { n: '苏姆' },
            TAP: { n: '塔波阿' },
            TUY: { n: '图伊' },
            HOU: { n: '乌埃' },
            OUB: { n: '乌布里滕加' },
            OUD: { n: '乌达兰' },
            SIS: { n: '锡西里' },
            YAG: { n: '亚加' },
            YAT: { n: '亚滕加' },
            IOA: { n: '伊奥巴' },
            ZOW: { n: '宗德韦奥戈' },
            ZOD: { n: '宗多马' }
        }
    },
    BDI: {
        n: '布隆迪',
        0: {
            n: '',
            BB: { n: '布班扎' },
            BR: { n: '布鲁里' },
            BM: { n: '布琼布拉城市' },
            BU: { n: '布琼布拉乡村' },
            NG: { n: '恩戈齐' },
            KI: { n: '基龙多' },
            GI: { n: '基特加' },
            KR: { n: '卡鲁济' },
            KY: { n: '卡扬扎' },
            CA: { n: '坎库佐' },
            RT: { n: '鲁塔纳' },
            RY: { n: '鲁伊吉' },
            MA: { n: '马坎巴' },
            MU: { n: '穆拉姆维亚' },
            MW: { n: '穆瓦洛' },
            MY: { n: '穆因加' },
            CI: { n: '锡比托凯' }
        }
    },
    BVT: { n: '布韦岛', 0: { n: '', 0: { n: '' } } },
    PRK: {
        n: '朝鲜',
        0: {
            n: '',
            HAE: { n: '海州' },
            HYE: { n: '惠山' },
            KAN: { n: '江界' },
            KSN: { n: '开城' },
            NAS: { n: '罗先' },
            NAM: { n: '南浦' },
            FNJ: { n: '平壤' },
            CHO: { n: '清津' },
            SAR: { n: '沙里院' },
            HAM: { n: '咸兴' },
            SII: { n: '新义州' },
            WON: { n: '元山' }
        }
    },
    DNK: {
        n: '丹麦',
        0: {
            n: '',
            AR: { n: '奥胡斯' },
            VSV: { n: '北日德兰' },
            BO: { n: '博恩霍尔姆' },
            FRE: { n: '菲特烈堡' },
            FY: { n: '菲茵' },
            CPH: { n: '哥本哈根' },
            RIB: { n: '里伯' },
            RKG: { n: '灵克宾' },
            RKE: { n: '罗斯基勒' },
            VBI: { n: '南日德兰' },
            ST: { n: '斯多斯特姆' },
            VIB: { n: '维堡' },
            VEJ: { n: '维厄勒' },
            VS: { n: '西希兰' }
        }
    },
    DEU: {
        n: '德国',
        0: {
            n: '',
            ARN: { n: '阿恩斯贝格' },
            ERF: { n: '爱尔福特' },
            ANS: { n: '安斯巴格' },
            AGB: { n: '奥格斯堡' },
            BE: { n: '柏林' },
            BYU: { n: '拜伊罗特' },
            BFE: { n: '比勒费尔德' },
            POT: { n: '波茨坦' },
            BOM: { n: '波鸿' },
            HB: { n: '不来梅' },
            BRW: { n: '不伦瑞克' },
            DAR: { n: '达姆施塔特' },
            DET: { n: '代特莫尔特' },
            DRS: { n: '德累斯顿' },
            DES: { n: '德绍' },
            DUS: { n: '杜塞尔多夫' },
            FFO: { n: '法兰克福' },
            FBG: { n: '弗赖堡' },
            HAE: { n: '哈雷' },
            HH: { n: '汉堡' },
            HAJ: { n: '汉诺威' },
            KEL: { n: '基尔' },
            GBN: { n: '吉森' },
            KAE: { n: '卡尔斯鲁厄' },
            KAS: { n: '卡塞尔' },
            CHE: { n: '开姆尼斯' },
            KOB: { n: '科布伦次' },
            CGN: { n: '科隆' },
            LEJ: { n: '莱比锡' },
            LDH: { n: '兰茨胡特' },
            LBG: { n: '吕讷堡' },
            MAG: { n: '马格德堡' },
            MHG: { n: '曼海姆' },
            MAI: { n: '美因兹' },
            MUN: { n: '明斯特' },
            MUC: { n: '慕尼黑' },
            NUE: { n: '纽伦堡' },
            SWH: { n: '什未林' },
            STR: { n: '斯图加特' },
            TRI: { n: '特里尔' },
            WIB: { n: '威斯巴登' },
            WUG: { n: '维尔茨堡' }
        }
    },
    TLS: {
        n: '东帝汶',
        0: {
            n: '',
            AL: { n: '阿伊莱乌' },
            AN: { n: '阿伊纳罗' },
            ER: { n: '埃尔梅拉' },
            AM: { n: '安贝诺' },
            BA: { n: '包考' },
            BO: { n: '博博纳罗' },
            DI: { n: '帝力' },
            KO: { n: '科瓦利马' },
            LA: { n: '劳滕' },
            LI: { n: '利基卡' },
            MT: { n: '马纳图托' },
            MF: { n: '马努法伊' },
            VI: { n: '维克克' }
        }
    },
    TGO: {
        n: '多哥',
        0: {
            n: '',
            M: { n: '滨海区' },
            S: { n: '草原区' },
            P: { n: '高原区' },
            K: { n: '卡拉区' },
            C: { n: '中部区' }
        }
    },
    DMA: { n: '多米尼加', 0: { n: '', 0: { n: '' } } },
    DOM: { n: '多米尼加共和国', 0: { n: '', 0: { n: '' } } },
    RUS: {
        n: '俄罗斯',
        0: {
            n: '',
            ABA: { n: '阿巴坎' },
            ARK: { n: '阿尔汉格尔斯克' },
            AGI: { n: '阿金斯科耶' },
            DYR: { n: '阿纳德尔' },
            AST: { n: '阿斯特拉罕' },
            ESL: { n: '埃利斯塔' },
            ORL: { n: '奥廖尔' },
            ORE: { n: '奥伦堡' },
            BAX: { n: '巴尔瑙尔' },
            PNZ: { n: '奔萨' },
            PKC: { n: '彼得罗巴甫洛夫斯克' },
            PES: { n: '彼得罗扎沃茨克' },
            PER: { n: '彼尔姆' },
            BBZ: { n: '比罗比詹' },
            BEL: { n: '别尔哥罗德' },
            COK: { n: '伯力' },
            BQS: { n: '布拉戈维申斯克' },
            BRY: { n: '布良斯克' },
            CHE: { n: '车里雅宾斯克' },
            CHI: { n: '赤塔' },
            ROS: { n: '顿河畔罗斯托夫' },
            OMS: { n: '鄂木斯克' },
            VOG: { n: '伏尔加格勒' },
            VMR: { n: '弗拉基米尔' },
            VLA: { n: '弗拉季高加索' },
            GOA: { n: '戈尔诺－阿尔泰斯克' },
            GRV: { n: '格罗兹尼' },
            VVO: { n: '海参崴' },
            KHM: { n: '汉特－曼西斯克' },
            KIR: { n: '基洛夫' },
            KGD: { n: '加里宁格勒' },
            KZN: { n: '喀山' },
            KLF: { n: '卡卢加' },
            KOS: { n: '科斯特罗马' },
            KRR: { n: '克拉斯诺达尔' },
            KYA: { n: '克拉斯诺亚尔斯克' },
            KEM: { n: '克麦罗沃' },
            KYZ: { n: '克孜勒' },
            KUD: { n: '库德姆卡尔' },
            KRO: { n: '库尔干' },
            URS: { n: '库尔斯克' },
            LIP: { n: '利佩茨克' },
            RYA: { n: '梁赞' },
            MCX: { n: '马哈奇卡拉' },
            MAG: { n: '马加丹' },
            IN: { n: '马加斯' },
            MAY: { n: '迈科普' },
            MMK: { n: '摩尔曼斯克' },
            MOW: { n: '莫斯科' },
            NAL: { n: '纳尔奇克' },
            NNM: { n: '纳里扬马尔' },
            JSA: { n: '南萨哈林斯克' },
            VUS: { n: '诺夫哥罗德' },
            PAL: { n: '帕拉纳' },
            PSK: { n: '普斯科夫' },
            CSY: { n: '切博克萨雷' },
            CKS: { n: '切尔克斯克' },
            TYU: { n: '秋明' },
            SAR: { n: '萨拉托夫' },
            SKX: { n: '萨兰斯克' },
            SLY: { n: '萨列哈尔德' },
            SAM: { n: '萨马拉' },
            SCW: { n: '瑟克特夫卡尔' },
            SPE: { n: '圣彼得堡' },
            LNX: { n: '斯摩棱斯克' },
            STA: { n: '斯塔夫罗波尔' },
            TAM: { n: '坦波夫' },
            TVE: { n: '特维尔' },
            TUL: { n: '图拉' },
            TOM: { n: '托木斯克' },
            VOR: { n: '沃罗涅什' },
            VLG: { n: '沃洛格达' },
            UFA: { n: '乌法' },
            UUD: { n: '乌兰乌德' },
            ULY: { n: '乌里扬诺夫斯克' },
            UOB: { n: '乌斯季奥尔登斯基' },
            GOJ: { n: '下诺夫哥罗德' },
            NVS: { n: '新西伯利亚' },
            JAK: { n: '雅库茨克' },
            JAR: { n: '雅罗斯拉夫尔' },
            JEK: { n: '叶卡捷林堡' },
            IKT: { n: '伊尔库茨克' },
            IJK: { n: '伊热夫斯克' },
            IVO: { n: '伊万诺沃' },
            YOL: { n: '约什卡尔奥拉' }
        }
    },
    ECU: {
        n: '厄瓜多尔',
        0: {
            n: '',
            A: { n: '阿苏艾' },
            O: { n: '埃尔奥罗' },
            E: { n: '埃斯梅拉尔达斯' },
            B: { n: '玻利瓦尔' },
            G: { n: '瓜亚斯' },
            W: { n: '加拉帕戈斯' },
            C: { n: '卡尔奇' },
            F: { n: '卡尼亚尔' },
            X: { n: '科托帕希' },
            L: { n: '洛哈' },
            R: { n: '洛斯里奥斯' },
            M: { n: '马纳比' },
            S: { n: '莫罗纳－圣地亚哥' },
            D: { n: '纳波，奥雷利亚纳' },
            Y: { n: '帕斯塔萨' },
            P: { n: '皮钦查' },
            H: { n: '钦博拉索' },
            Z: { n: '萨莫拉－钦奇佩' },
            U: { n: '苏昆毕奥斯' },
            T: { n: '通古拉瓦' },
            I: { n: '因巴布拉' }
        }
    },
    ERI: {
        n: '厄立特里亚',
        0: {
            n: '',
            KE: { n: '安塞巴' },
            SK: { n: '北红海' },
            BR: { n: '加什·巴尔卡' },
            DE: { n: '南部' },
            DK: { n: '南红海' },
            MA: { n: '中部' }
        }
    },
    FRA: {
        n: '法国',
        0: {
            n: '',
            ARL: { n: '阿尔勒' },
            AJA: { n: '阿雅克修' },
            QXB: { n: '艾克斯' },
            ORR: { n: '奥尔良' },
            PAR: { n: '巴黎' },
            BSN: { n: '贝桑松' },
            DIJ: { n: '第戎' },
            FRJ: { n: '弗雷瑞斯' },
            CFR: { n: '卡昂' },
            RNS: { n: '雷恩' },
            LIO: { n: '里昂' },
            LLE: { n: '里尔' },
            LIG: { n: '利摩日' },
            URO: { n: '鲁昂' },
            MRS: { n: '马赛' },
            MZM: { n: '梅斯' },
            MPL: { n: '蒙彼利埃' },
            NTE: { n: '南特' },
            NCE: { n: '尼斯' },
            CSM: { n: '沙隆' },
            TLS: { n: '图卢兹' },
            VAA: { n: '瓦朗斯' },
            AMI: { n: '亚眠' }
        }
    },
    FRO: { n: '法罗群岛', 0: { n: '', 0: { n: '' } } },
    PYF: { n: '法属波利尼西亚', 0: { n: '', 0: { n: '' } } },
    GUF: { n: '法属圭亚那', 0: { n: '', 0: { n: '' } } },
    ATF: { n: '法属南部领地', 0: { n: '', 0: { n: '' } } },
    VAT: { n: '梵蒂冈', 0: { n: '', 0: { n: '' } } },
    PHL: {
        n: '菲律宾',
        0: {
            n: '',
            DOR: { n: '达沃' },
            CAO: { n: '卡卢坎' },
            MNL: { n: '马尼拉' },
            CEB: { n: '宿务' }
        }
    },
    FJI: { n: '斐济', 0: { n: '', 0: { n: '' } } },
    FIN: {
        n: '芬兰',
        0: {
            n: '',
            ESP: { n: '埃斯波' },
            OLU: { n: '奥卢' },
            POR: { n: '波里' },
            PRV: { n: '博尔沃' },
            HMY: { n: '海门林纳' },
            HEL: { n: '赫尔辛基' },
            KAJ: { n: '卡亚尼' },
            KOK: { n: '科科拉' },
            KTK: { n: '科特卡' },
            KUO: { n: '库奥皮奥' },
            LHI: { n: '拉赫蒂' },
            LPP: { n: '拉彭兰塔' },
            RVN: { n: '罗瓦涅米' },
            MHQ: { n: '玛丽港' },
            MIK: { n: '米凯利' },
            TMP: { n: '坦佩雷' },
            TKU: { n: '图尔库' },
            VAA: { n: '瓦萨' },
            VAT: { n: '万塔' },
            JOE: { n: '约恩苏' }
        }
    },
    CPV: {
        n: '佛得角',
        0: {
            n: '',
            PA: { n: '保尔' },
            PN: { n: '波多诺伏' },
            BV: { n: '博阿维斯塔岛' },
            BR: { n: '布拉瓦岛' },
            RG: { n: '大里贝拉' },
            FO: { n: '福古岛' },
            MA: { n: '马尤岛' },
            MO: { n: '莫斯特罗' },
            PR: { n: '普拉亚' },
            SL: { n: '萨尔岛' },
            SA: { n: '圣安唐岛' },
            IA: { n: '圣地亚哥岛' },
            SD: { n: '圣多明戈' },
            SF: { n: '圣菲利普' },
            CA: { n: '圣卡塔琳娜' },
            CR: { n: '圣克鲁斯' },
            SM: { n: '圣米戈尔' },
            SN: { n: '圣尼古拉岛' },
            SV: { n: '圣维森特岛' },
            TA: { n: '塔拉法尔' }
        }
    },
    FLK: { n: '弗兰克群岛', 0: { n: '', 0: { n: '' } } },
    GMB: { n: '冈比亚', 0: { n: '', 0: { n: '' } } },
    COG: { n: '刚果', 0: { n: '', 0: { n: '' } } },
    COD: { n: '刚果民主共和国', 0: { n: '', 0: { n: '' } } },
    COL: {
        n: '哥伦比亚',
        0: {
            n: '',
            ARA: { n: '阿劳卡' },
            ANT: { n: '安提奥基亚' },
            NDS: { n: '北桑坦德' },
            BDC: { n: '波哥大首都区' },
            BOL: { n: '博利瓦尔' },
            BOY: { n: '博亚卡' },
            ATL: { n: '大西洋' },
            GVR: { n: '瓜维亚雷' },
            GJR: { n: '瓜希拉' },
            GNA: { n: '瓜伊尼亚' },
            QUI: { n: '金迪奥' },
            CAL: { n: '卡尔达斯' },
            CAQ: { n: '卡克塔' },
            CAS: { n: '卡萨纳雷' },
            CAU: { n: '考卡' },
            VDC: { n: '考卡山谷' },
            COR: { n: '科尔多巴' },
            CAM: { n: '昆迪纳马卡' },
            RIS: { n: '利萨拉尔达' },
            MAG: { n: '马格达雷那' },
            MET: { n: '梅塔' },
            NAR: { n: '纳里尼奥' },
            PUT: { n: '普图马约' },
            CHO: { n: '乔科' },
            CES: { n: '塞萨尔' },
            SAN: { n: '桑坦德' },
            SAP: { n: '圣安德烈斯-普罗维登西亚' },
            SUC: { n: '苏克雷' },
            TOL: { n: '托利马' },
            VIC: { n: '维查达' },
            VAU: { n: '沃佩斯' },
            HUI: { n: '乌伊拉' },
            AMZ: { n: '亚马孙' }
        }
    },
    CRI: {
        n: '哥斯达黎加',
        0: {
            n: '',
            A: { n: '阿拉胡埃拉' },
            H: { n: '埃雷迪亚' },
            G: { n: '瓜纳卡斯特' },
            C: { n: '卡塔戈' },
            L: { n: '利蒙' },
            P: { n: '蓬塔雷纳斯' },
            SJ: { n: '圣何塞' }
        }
    },
    GGY: { n: '格恩西岛', 0: { n: '', 0: { n: '' } } },
    GRD: { n: '格林纳达', 0: { n: '', 0: { n: '' } } },
    GRL: { n: '格陵兰', 0: { n: '', 0: { n: '' } } },
    CUB: {
        n: '古巴',
        0: {
            n: '',
            11: { n: '奥尔金' },
            1: { n: '比那尔德里奥' },
            5: { n: '比亚克拉拉' },
            12: { n: '格拉玛' },
            14: { n: '关塔那摩' },
            2: { n: '哈瓦那' },
            3: { n: '哈瓦那城' },
            9: { n: '卡马圭' },
            10: { n: '拉斯图纳斯' },
            4: { n: '马坦萨斯' },
            MAY: { n: '马亚里' },
            MZO: { n: '曼萨尼罗' },
            99: { n: '青年岛特区' },
            13: { n: '圣地亚哥' },
            7: { n: '圣斯皮里图斯' },
            6: { n: '西恩富戈斯' },
            8: { n: '谢戈德阿维拉' }
        }
    },
    GLP: { n: '瓜德罗普', 0: { n: '', 0: { n: '' } } },
    GUM: { n: '关岛', 0: { n: '', 0: { n: '' } } },
    GUY: {
        n: '圭亚那',
        0: {
            n: '',
            EW: { n: '埃塞奎博群岛-西德梅拉拉' },
            BW: { n: '巴里马-瓦伊尼' },
            PM: { n: '波默伦-苏佩纳姆' },
            PI: { n: '波塔罗-锡帕鲁尼' },
            DM: { n: '德梅拉拉-马海卡' },
            EC: { n: '东伯比斯-科兰太因' },
            CM: { n: '库尤尼-马扎鲁尼' },
            MB: { n: '马海卡-伯比斯' },
            UD: { n: '上德梅拉拉-伯比斯' },
            UT: { n: '上塔库图-上埃塞奎博' }
        }
    },
    KAZ: {
        n: '哈萨克斯坦',
        0: {
            n: '',
            AYK: { n: '阿尔卡累克' },
            AKM: { n: '阿克莫拉' },
            AKS: { n: '阿克苏' },
            AKT: { n: '阿克托别' },
            ALA: { n: '阿拉木图' },
            ARY: { n: '阿雷斯' },
            AST: { n: '阿斯塔纳市' },
            ATY: { n: '阿特劳' },
            EKB: { n: '埃基巴斯图兹' },
            BXH: { n: '巴尔喀什' },
            PAV: { n: '巴甫洛达尔' },
            SEV: { n: '北哈萨克斯坦' },
            VOS: { n: '东哈萨克斯坦' },
            ZYR: { n: '济良诺夫斯克' },
            DMB: { n: '江布尔' },
            DZH: { n: '杰兹卡兹甘' },
            KAR: { n: '卡拉干达' },
            KZO: { n: '卡拉扎尔' },
            KAP: { n: '卡普恰盖' },
            KST: { n: '科斯塔奈' },
            KZY: { n: '克孜勒奥尔达' },
            KEN: { n: '肯套' },
            KUR: { n: '库尔恰托夫' },
            LKK: { n: '利萨科夫斯克' },
            LEN: { n: '列宁诺戈尔斯克' },
            RUD: { n: '鲁德内' },
            MAN: { n: '曼格斯套' },
            KGT: { n: '南哈萨克斯坦' },
            SAR: { n: '萨兰' },
            SEM: { n: '塞梅伊' },
            SAK: { n: '沙赫京斯克' },
            STE: { n: '斯捷普诺戈尔斯克' },
            TEK: { n: '铁克利' },
            TEM: { n: '铁米尔套' },
            TUR: { n: '突厥斯坦' },
            ZAP: { n: '西哈萨克斯坦' },
            ZHA: { n: '扎纳奥津' }
        }
    },
    HTI: { n: '海地', 0: { n: '', 0: { n: '' } } },
    KOR: {
        n: '韩国',
        27: {
            n: '大邱',
            DSG: { n: '达城郡' },
            TAE: { n: '大邱' },
            SUS: { n: '寿城区' }
        },
        30: { n: '大田', 0: { n: '' } },
        26: { n: '釜山', 0: { n: '' } },
        29: { n: '光州', 0: { n: '' } },
        41: { n: '济州特别自治道', 0: { n: '' } },
        42: {
            n: '江原道',
            CHC: { n: '春川市' },
            TGH: { n: '东海市' },
            GSG: { n: '高城郡' },
            HSG: { n: '横城郡' },
            HCN: { n: '洪川郡' },
            HCH: { n: '华川郡' },
            KAG: { n: '江陵市' },
            JSE: { n: '旌善郡' },
            IJE: { n: '麟蹄郡' },
            YWL: { n: '宁越郡' },
            POG: { n: '平昌郡' },
            SUK: { n: '三陟市' },
            SHO: { n: '束草市' },
            TBK: { n: '太白市' },
            CWN: { n: '铁原郡' },
            YNY: { n: '襄阳郡' },
            YGU: { n: '杨口郡' },
            WJU: { n: '原州市' }
        },
        49: {
            n: '京畿道',
            ASG: { n: '安城市' },
            ASN: { n: '安山市' },
            ANY: { n: '安养市' },
            POC: { n: '抱川市' },
            SEO: { n: '城南市' },
            DDC: { n: '东豆川市' },
            BCN: { n: '富川市' },
            GYG: { n: '高阳市' },
            GMG: { n: '光明市' },
            KWU: { n: '广州市' },
            GCN: { n: '果川市' },
            HNM: { n: '河南市' },
            HCH: { n: '华城市' },
            GPG: { n: '加平郡' },
            GMP: { n: '金浦市' },
            GRI: { n: '九里市' },
            GUN: { n: '军浦市' },
            YJU: { n: '骊州郡' },
            ICE: { n: '利川市' },
            YCN: { n: '涟川郡' },
            YNG: { n: '龙仁市' },
            NYU: { n: '南杨州市' },
            PTK: { n: '平泽市' },
            PJU: { n: '坡州市' },
            SHE: { n: '始兴市' },
            SUO: { n: '水原市' },
            OSN: { n: '乌山市' },
            YPG: { n: '扬平郡' },
            YYU: { n: '杨州市' },
            UWN: { n: '仪旺市' },
            UIJ: { n: '议政府市' }
        },
        43: {
            n: '庆尚北道',
            ADG: { n: '安东市' },
            BHA: { n: '奉化郡' },
            GRG: { n: '高灵郡' },
            KUM: { n: '龟尾市' },
            KMC: { n: '金泉市' },
            GWI: { n: '军威郡' },
            YEC: { n: '醴泉郡' },
            KPO: { n: '浦项市' },
            CGK: { n: '漆谷郡' },
            CDO: { n: '淸道郡' },
            CSG: { n: '靑松郡' },
            GYS: { n: '庆山市' },
            GJU: { n: '庆州市' },
            YEJ: { n: '荣州市' },
            SJU: { n: '尙州市' },
            UJN: { n: '蔚珍郡' },
            MGG: { n: '闻庆市' },
            SEJ: { n: '星州郡' },
            USG: { n: '义城郡' },
            YYG: { n: '英阳郡' },
            YDK: { n: '盈德郡' },
            YCH: { n: '永川市' },
            ULG: { n: '郁陵郡' }
        },
        44: {
            n: '庆尚南道',
            CNG: { n: '昌宁郡' },
            CHW: { n: '昌原市' },
            GSO: { n: '固城郡' },
            HDG: { n: '河东郡' },
            KMH: { n: '金海市' },
            HIN: { n: '晋州市' },
            GCH: { n: '居昌郡' },
            KJE: { n: '巨济市' },
            YSN: { n: '梁山市' },
            MAS: { n: '马山市' },
            MIR: { n: '密阳市' },
            NHE: { n: '南海郡' },
            SCH: { n: '山淸郡' },
            SAH: { n: '泗川市' },
            TYG: { n: '统营市' },
            HCE: { n: '陜川郡' },
            HAN: { n: '咸安郡' },
            HYG: { n: '咸阳郡' },
            URG: { n: '宜宁郡' },
            CHF: { n: '鎭海市' }
        },
        47: {
            n: '全罗北道',
            SCG: { n: '淳昌郡' },
            PUS: { n: '扶安郡' },
            GCG: { n: '高敞郡' },
            GJE: { n: '金堤市' },
            JEO: { n: '井邑市' },
            MJU: { n: '茂朱郡' },
            NWN: { n: '南原市' },
            JNJ: { n: '全州市' },
            KUV: { n: '群山市' },
            ISL: { n: '任实郡' },
            WAJ: { n: '完州郡' },
            IKS: { n: '益山市' },
            JSU: { n: '长水郡' },
            JAN: { n: '鎭安郡' }
        },
        48: {
            n: '全罗南道',
            BSG: { n: '宝城郡' },
            GHG: { n: '高兴郡' },
            GSE: { n: '谷城郡' },
            WND: { n: '莞岛郡' },
            KAN: { n: '光阳市' },
            HAE: { n: '海南郡' },
            HSN: { n: '和顺郡' },
            GJN: { n: '康津郡' },
            YOS: { n: '丽水市' },
            YGG: { n: '灵光郡' },
            YAM: { n: '灵岩郡' },
            NJU: { n: '罗州市' },
            MOK: { n: '木浦市' },
            GRE: { n: '求礼郡' },
            SYS: { n: '顺天市' },
            DYA: { n: '潭阳郡' },
            MAN: { n: '务安郡' },
            HPG: { n: '咸平郡' },
            SAN: { n: '新安郡' },
            JSN: { n: '长城郡' },
            JHG: { n: '长兴郡' },
            JDO: { n: '珍岛郡' }
        },
        28: { n: '仁川', 0: { n: '' } },
        11: { n: '首尔', 0: { n: '' } },
        31: { n: '蔚山', 0: { n: '' } },
        45: {
            n: '忠清北道',
            BEN: { n: '报恩郡' },
            JYG: { n: '曾坪郡' },
            DYG: { n: '丹阳郡' },
            JCH: { n: '堤川市' },
            GSN: { n: '槐山郡' },
            CWO: { n: '淸原郡' },
            CJJ: { n: '淸州市' },
            OCN: { n: '沃川郡' },
            ESG: { n: '阴城郡' },
            YDG: { n: '永同郡' },
            JCN: { n: '鎭川郡' },
            CHU: { n: '忠州市' }
        },
        46: {
            n: '忠清南道',
            BOR: { n: '保宁市' },
            BYO: { n: '扶余郡' },
            GOJ: { n: '公州市' },
            HSE: { n: '洪城郡' },
            GYE: { n: '鸡龙市' },
            GSA: { n: '锦山郡' },
            YOS: { n: '礼山郡' },
            NSN: { n: '论山市' },
            CYG: { n: '青阳郡' },
            SSA: { n: '瑞山市' },
            SCE: { n: '舒川郡' },
            TAN: { n: '泰安郡' },
            TJI: { n: '唐津郡' },
            CHO: { n: '天安市' },
            ASA: { n: '牙山市' },
            YGI: { n: '燕岐郡' }
        }
    },
    NLD: {
        n: '荷兰',
        0: {
            n: '',
            AER: { n: '阿尔梅勒' },
            AME: { n: '阿默斯福特' },
            AMS: { n: '阿姆斯特丹' },
            ARN: { n: '阿纳姆' },
            APE: { n: '阿珀尔多伦' },
            ASS: { n: '阿森' },
            EDE: { n: '埃德' },
            EMM: { n: '埃门' },
            EIN: { n: '埃因霍芬' },
            BRD: { n: '布雷达' },
            TLB: { n: '蒂尔堡' },
            DOR: { n: '多德雷赫特' },
            ENS: { n: '恩斯赫德' },
            GRQ: { n: '格罗宁根' },
            HRA: { n: '哈勒姆' },
            HAG: { n: '海牙' },
            HFD: { n: '霍夫多尔普' },
            LID: { n: '莱顿' },
            LEY: { n: '莱利斯塔德' },
            RTM: { n: '鹿特丹' },
            LWR: { n: '吕伐登' },
            MST: { n: '马斯特里赫特' },
            MDL: { n: '米德尔堡' },
            NIJ: { n: '奈梅亨' },
            HTB: { n: '斯海尔托亨博思' },
            UTC: { n: '乌得勒支' },
            ZWO: { n: '兹沃勒' },
            ZTM: { n: '佐特尔梅' }
        }
    },
    ANT: { n: '荷属安地列斯', 0: { n: '', 0: { n: '' } } },
    HMD: { n: '赫德和麦克唐纳群岛', 0: { n: '', 0: { n: '' } } },
    HND: {
        n: '洪都拉斯',
        0: {
            n: '',
            AT: { n: '阿特兰蒂达' },
            PA: { n: '埃尔帕拉伊索' },
            OC: { n: '奥科特佩克' },
            OL: { n: '奥兰乔' },
            FM: { n: '弗朗西斯科-莫拉桑' },
            GD: { n: '格拉西亚斯-阿迪奥斯' },
            IB: { n: '海湾群岛' },
            CR: { n: '科尔特斯' },
            CL: { n: '科隆' },
            CM: { n: '科马亚瓜' },
            CP: { n: '科潘' },
            PZ: { n: '拉巴斯' },
            LE: { n: '伦皮拉' },
            CH: { n: '乔卢特卡' },
            CHO: { n: '乔罗马' },
            VA: { n: '山谷' },
            SB: { n: '圣巴巴拉' },
            IN: { n: '因蒂布卡' },
            YO: { n: '约罗' }
        }
    },
    KIR: {
        n: '基里巴斯',
        0: {
            n: '',
            PHO: { n: '菲尼克斯群岛' },
            GIL: { n: '吉尔伯特群岛' },
            LIN: { n: '莱恩群岛' }
        }
    },
    DJI: {
        n: '吉布提',
        0: {
            n: '',
            S: { n: '阿里萨比赫区' },
            O: { n: '奥博克区' },
            K: { n: '迪基勒区' },
            T: { n: '塔朱拉区' }
        }
    },
    KGZ: {
        n: '吉尔吉斯斯坦',
        0: {
            n: '',
            O: { n: '奥什' },
            B: { n: '巴特肯' },
            GB: { n: '比什凯克市' },
            C: { n: '楚河' },
            J: { n: '贾拉拉巴德' },
            KBA: { n: '卡拉巴尔塔' },
            KKO: { n: '卡拉库尔' },
            KAN: { n: '坎特' },
            KJ: { n: '科克扬加克' },
            MS: { n: '迈利赛' },
            N: { n: '纳伦' },
            SU: { n: '苏卢克图' },
            T: { n: '塔拉斯' },
            TK: { n: '塔什库梅尔' },
            UG: { n: '乌兹根' },
            Y: { n: '伊塞克湖' }
        }
    },
    GIN: {
        n: '几内亚',
        0: {
            n: '',
            BOK: { n: '博凯' },
            NZR: { n: '恩泽雷科雷' },
            FRN: { n: '法拉纳' },
            KND: { n: '金迪亚' },
            KNK: { n: '康康' },
            CNK: { n: '科纳克里' },
            LAB: { n: '拉贝' },
            MAM: { n: '玛木' }
        }
    },
    GNB: { n: '几内亚比绍', 0: { n: '', 0: { n: '' } } },
    CAN: {
        n: '加拿大',
        0: {
            n: '',
            ABB: { n: '阿伯茨福' },
            EDM: { n: '埃德蒙顿' },
            OSH: { n: '奥沙瓦' },
            BAR: { n: '巴里' },
            CBR: { n: '布列塔尼角' },
            TOR: { n: '多伦多' },
            FRE: { n: '弗雷德里顿' },
            GLP: { n: '圭尔夫' },
            HAL: { n: '哈利法克斯' },
            HAM: { n: '哈密尔顿' },
            YXY: { n: '怀特霍斯' },
            KWL: { n: '基劳纳' },
            BRP: { n: '基奇纳' },
            KGN: { n: '金斯敦' },
            CAL: { n: '卡里加里' },
            QUE: { n: '魁北克' },
            REG: { n: '里贾纳' },
            LOD: { n: '伦敦' },
            MTR: { n: '蒙特利尔' },
            SUD: { n: '萨德伯里' },
            SAK: { n: '萨斯卡通' },
            TRR: { n: '三河城' },
            THU: { n: '桑德贝' },
            SBE: { n: '舍布鲁克' },
            SCA: { n: '圣卡塔琳娜' },
            SJB: { n: '圣约翰斯' },
            VIC: { n: '维多利亚' },
            VAN: { n: '温哥华' },
            WNP: { n: '温尼伯' },
            WDR: { n: '温莎' },
            OTT: { n: '渥太华' },
            CHA: { n: '夏洛特敦' },
            YZF: { n: '耶洛奈夫' },
            IQL: { n: '伊魁特' }
        }
    },
    GHA: {
        n: '加纳',
        0: {
            n: '',
            AS: { n: '阿散蒂' },
            OBU: { n: '奥布阿西' },
            NO: { n: '北部' },
            BA: { n: '布朗阿哈福' },
            GA: { n: '大阿克拉' },
            EA: { n: '东部' },
            UE: { n: '上东部' },
            UW: { n: '上西部' },
            VO: { n: '沃尔特' },
            WE: { n: '西部' },
            CE: { n: '中部' }
        }
    },
    GAB: {
        n: '加蓬',
        0: {
            n: '',
            OL: { n: '奥果韦-洛洛' },
            OI: { n: '奥果韦-伊温多' },
            OM: { n: '滨海奥果韦' },
            NG: { n: '恩古涅' },
            ES: { n: '河口' },
            NY: { n: '尼扬加' },
            HO: { n: '上奥果韦' },
            WN: { n: '沃勒-恩特姆' },
            MO: { n: '中奥果韦' }
        }
    },
    KHM: {
        n: '柬埔寨',
        0: {
            n: '',
            OC: { n: '奥多棉吉' },
            KB: { n: '白马市' },
            PR: { n: '柏威夏' },
            PL: { n: '拜林市' },
            BM: { n: '班迭棉吉' },
            KZC: { n: '磅清扬' },
            KO: { n: '磅士卑' },
            KZK: { n: '磅同' },
            KM: { n: '磅湛' },
            PG: { n: '波罗勉' },
            TK: { n: '茶胶' },
            SVR: { n: '柴桢' },
            KL: { n: '干丹' },
            KKZ: { n: '戈公' },
            KMT: { n: '贡布' },
            PNH: { n: '金边市' },
            KH: { n: '桔井' },
            RBE: { n: '腊塔纳基里' },
            BA: { n: '马德望' },
            MWV: { n: '蒙多基里' },
            PO: { n: '菩萨' },
            TNX: { n: '上丁' },
            KA: { n: '西哈努克市' },
            REP: { n: '暹粒' }
        }
    },
    CZE: {
        n: '捷克',
        0: {
            n: '',
            OL: { n: '奥洛穆茨' },
            PL: { n: '比尔森' },
            PR: { n: '布拉格直辖市' },
            KR: { n: '赫拉德茨-克拉洛韦' },
            KA: { n: '卡罗维发利' },
            LI: { n: '利贝雷克' },
            MO: { n: '摩拉维亚-西里西亚' },
            JC: { n: '南摩拉维亚' },
            PA: { n: '帕尔杜比采' },
            VY: { n: '维索基纳' },
            US: { n: '乌斯季' },
            ST: { n: '中捷克' },
            ZL: { n: '兹林' }
        }
    },
    ZWE: {
        n: '津巴布韦',
        0: {
            n: '',
            MN: { n: '北马塔贝莱兰' },
            BU: { n: '布拉瓦约' },
            ME: { n: '东马绍纳兰' },
            HA: { n: '哈拉雷' },
            ML: { n: '马尼卡兰' },
            MV: { n: '马斯温戈' },
            MS: { n: '南马塔贝莱兰' },
            MW: { n: '西马绍纳兰' },
            MD: { n: '中部' },
            MC: { n: '中马绍纳兰' }
        }
    },
    CMR: {
        n: '喀麦隆',
        0: {
            n: '',
            ADA: { n: '阿达马瓦' },
            NOR: { n: '北部' },
            EXN: { n: '北端' },
            LIT: { n: '滨海' },
            EST: { n: '东部' },
            SUD: { n: '南部' },
            NOT: { n: '西北' },
            OUE: { n: '西部' },
            SOU: { n: '西南' },
            CEN: { n: '中央' }
        }
    },
    QAT: {
        n: '卡塔尔',
        0: {
            n: '',
            MS: { n: '北部' },
            DW: { n: '多哈' },
            GW: { n: '古韦里耶' },
            KR: { n: '豪尔' },
            JB: { n: '杰里扬拜特奈' },
            RN: { n: '赖扬' },
            WK: { n: '沃克拉' },
            UL: { n: '乌姆锡拉勒' },
            JM: { n: '朱迈利耶' }
        }
    },
    CYM: { n: '开曼群岛', 0: { n: '', 0: { n: '' } } },
    CCK: { n: '科科斯群岛', 0: { n: '', 0: { n: '' } } },
    COM: { n: '科摩罗', 0: { n: '', 0: { n: '' } } },
    CIV: {
        n: '科特迪瓦',
        0: {
            n: '',
            AG: { n: '阿涅比' },
            BF: { n: '巴芬' },
            VB: { n: '邦达马河谷' },
            DE: { n: '登盖莱' },
            NC: { n: '恩济－科莫埃' },
            FR: { n: '弗罗马格尔' },
            LC: { n: '湖泊' },
            MR: { n: '马拉韦' },
            SB: { n: '南邦达马' },
            SC: { n: '南科莫埃' },
            HT: { n: '萨桑德拉' },
            SV: { n: '萨瓦纳' },
            DH: { n: '山地' },
            WR: { n: '沃罗杜古' },
            BS: { n: '下萨桑德拉' },
            LG: { n: '泻湖' },
            ZA: { n: '赞赞' },
            MV: { n: '中卡瓦利' },
            MC: { n: '中科莫埃' }
        }
    },
    KWT: { n: '科威特', 0: { n: '', 0: { n: '' } } },
    HRV: {
        n: '克罗地亚',
        0: {
            n: '',
            14: { n: '奥西耶克-巴拉尼亚' },
            7: { n: '别洛瓦尔-比洛戈拉' },
            8: { n: '滨海和山区' },
            11: { n: '波热加-斯拉沃尼亚' },
            12: { n: '布罗德-波萨维纳' },
            19: { n: '杜布罗夫斯克-内雷特瓦' },
            4: { n: '卡尔洛瓦茨' },
            6: { n: '科普里夫尼察-克里热夫齐' },
            2: { n: '克拉皮纳-扎戈列' },
            9: { n: '利卡-塞尼' },
            20: { n: '梅吉穆列' },
            1: { n: '萨格勒布' },
            21: { n: '萨格勒布市' },
            17: { n: '斯普利特-达尔马提亚' },
            5: { n: '瓦拉日丁' },
            10: { n: '维罗维蒂察-波德拉维纳' },
            16: { n: '武科瓦尔-斯里耶姆' },
            15: { n: '希贝尼克-克宁' },
            3: { n: '锡萨克-莫斯拉维纳' },
            18: { n: '伊斯特拉' },
            13: { n: '扎达尔' }
        }
    },
    KEN: {
        n: '肯尼亚',
        0: {
            n: '',
            EMA: { n: '埃尔格约-马拉奎特' },
            BAR: { n: '巴林戈' },
            BUN: { n: '邦戈马' },
            BOM: { n: '博美特' },
            BUS: { n: '布希亚' },
            EMB: { n: '恩布' },
            HOB: { n: '霍马湾' },
            KIA: { n: '基安布' },
            KIL: { n: '基里菲' },
            KIR: { n: '基里尼亚加' },
            KIS: { n: '基苏木' },
            KIT: { n: '基图伊' },
            KII: { n: '基西' },
            GAS: { n: '加里萨' },
            KAK: { n: '卡卡梅加' },
            KAJ: { n: '卡耶亚多' },
            KEY: { n: '凯里乔' },
            KWA: { n: '夸勒' },
            LAU: { n: '拉木' },
            LAI: { n: '莱基皮亚' },
            MAC: { n: '马查科斯' },
            MAK: { n: '马瓜尼' },
            RBT: { n: '马萨布布' },
            MAN: { n: '曼德拉' },
            MER: { n: '梅鲁' },
            MOM: { n: '蒙巴萨' },
            MIG: { n: '米戈利' },
            MUR: { n: '穆兰卡' },
            NUU: { n: '纳库鲁' },
            NAR: { n: '纳罗克' },
            NAN: { n: '南迪' },
            NA: { n: '内罗毕' },
            NIT: { n: '尼蒂' },
            NYM: { n: '尼亚米拉' },
            NYN: { n: '年达鲁阿' },
            NYE: { n: '涅里' },
            UAS: { n: '桑布卢' },
            TRI: { n: '塔纳河' },
            TTA: { n: '泰塔塔维塔' },
            TNZ: { n: '特兰斯-恩佐亚' },
            TUR: { n: '图尔卡纳' },
            WJR: { n: '瓦吉尔' },
            UGI: { n: '瓦辛基苏' },
            VIH: { n: '韦希加' },
            WPO: { n: '西波克特' },
            SIA: { n: '夏亚' },
            ISI: { n: '伊希约洛' },
            CE: { n: '中央' }
        }
    },
    COK: { n: '库克群岛', 0: { n: '', 0: { n: '' } } },
    LVA: {
        n: '拉脱维亚',
        0: {
            n: '',
            ALU: { n: '阿卢克斯内' },
            AIZ: { n: '爱兹克劳克雷' },
            OGR: { n: '奥格雷' },
            BAL: { n: '巴尔维' },
            BAU: { n: '包斯卡' },
            CES: { n: '采西斯' },
            DOB: { n: '多贝莱' },
            GUL: { n: '古尔贝内' },
            JEK: { n: '杰卡布皮尔斯' },
            KRA: { n: '克拉斯拉瓦' },
            KUL: { n: '库尔迪加' },
            RZR: { n: '雷泽克内' },
            RGA: { n: '里加' },
            LPK: { n: '利耶帕亚' },
            LIM: { n: '林巴济' },
            LUD: { n: '卢扎' },
            MAD: { n: '马多纳' },
            PRE: { n: '普雷利' },
            SAL: { n: '萨尔杜斯' },
            TAL: { n: '塔尔西' },
            DGR: { n: '陶格夫皮尔斯' },
            TUK: { n: '图库马' },
            VLK: { n: '瓦尔加' },
            VLM: { n: '瓦尔米耶拉' },
            VSL: { n: '文茨皮尔斯' },
            JGR: { n: '叶尔加瓦' }
        }
    },
    LSO: {
        n: '莱索托',
        0: {
            n: '',
            D: { n: '伯里亚' },
            B: { n: '布塔布泰' },
            G: { n: '古廷' },
            H: { n: '加查斯内克' },
            C: { n: '莱里贝' },
            E: { n: '马费滕' },
            A: { n: '马塞卢' },
            F: { n: '莫哈莱斯胡克' },
            J: { n: '莫霍特隆' },
            K: { n: '塔巴采卡' }
        }
    },
    LAO: {
        n: '老挝',
        0: {
            n: '',
            AT: { n: '阿速坡' },
            BL: { n: '波里坎赛' },
            BK: { n: '博乔' },
            XI: { n: '川圹' },
            PH: { n: '丰沙里' },
            KH: { n: '甘蒙' },
            HO: { n: '华潘' },
            LP: { n: '琅勃拉邦' },
            LM: { n: '琅南塔' },
            XN: { n: '赛宋本行政特区' },
            XE: { n: '色贡' },
            SL: { n: '沙拉湾' },
            SV: { n: '沙湾拿吉' },
            XA: { n: '沙耶武里' },
            VI: { n: '万象' },
            OU: { n: '乌多姆赛' },
            CH: { n: '占巴塞' }
        }
    },
    LBN: {
        n: '黎巴嫩',
        0: {
            n: '',
            AS: { n: '北部' },
            BI: { n: '贝卡' },
            BA: { n: '贝鲁特' },
            JL: { n: '黎巴嫩山' },
            NA: { n: '奈拜提耶市' },
            JA: { n: '南部' }
        }
    },
    LBR: {
        n: '利比里亚',
        0: {
            n: '',
            GBA: { n: '巴波卢' },
            BG: { n: '邦' },
            BOP: { n: '博波卢' },
            BM: { n: '博米' },
            GB: { n: '大巴萨' },
            GG: { n: '大吉德' },
            CM: { n: '大角山' },
            GK: { n: '大克鲁' },
            FT: { n: '菲什敦' },
            RG: { n: '吉河' },
            RI: { n: '里弗塞斯' },
            LO: { n: '洛法' },
            MG: { n: '马吉比' },
            MY: { n: '马里兰' },
            MO: { n: '蒙特塞拉多' },
            NI: { n: '宁巴' },
            SI: { n: '锡诺' }
        }
    },
    LBY: { n: '利比亚', 0: { n: '', 0: { n: '' } } },
    LTU: {
        n: '立陶宛',
        0: {
            n: '',
            AL: { n: '阿利图斯' },
            KA: { n: '考纳斯' },
            KL: { n: '克莱佩达' },
            MA: { n: '马里扬泊列' },
            PA: { n: '帕涅韦日斯' },
            TA: { n: '陶拉格' },
            TE: { n: '特尔希艾' },
            VI: { n: '维尔纽斯' },
            UT: { n: '乌田纳' },
            SI: { n: '希奥利艾' },
            AKM: { n: '亚克曼' }
        }
    },
    LIE: { n: '列支敦士登', 0: { n: '', 0: { n: '' } } },
    REU: { n: '留尼旺岛', 0: { n: '', 0: { n: '' } } },
    LUX: {
        n: '卢森堡',
        0: {
            n: '',
            DD: { n: '迪基希' },
            GG: { n: '格雷文马赫' },
            LL: { n: '卢森堡' }
        }
    },
    RWA: {
        n: '卢旺达',
        0: {
            n: '',
            BY: { n: '比温巴' },
            BU: { n: '布塔雷' },
            NY: { n: '恩延扎' },
            KG: { n: '基本古' },
            KY: { n: '基布耶' },
            KR: { n: '基加利-恩加利' },
            KV: { n: '基加利市' },
            GK: { n: '吉孔戈罗' },
            GS: { n: '吉塞尼' },
            GT: { n: '吉塔拉马' },
            KA: { n: '卡布加' },
            RW: { n: '卢瓦马加纳' },
            RH: { n: '鲁汉戈' },
            RU: { n: '鲁亨盖里' },
            CY: { n: '尚古古' },
            UM: { n: '乌姆塔拉' }
        }
    },
    ROU: {
        n: '罗马尼亚',
        0: {
            n: '',
            AL: { n: '阿尔巴尤利亚' },
            AR: { n: '阿拉德' },
            OR: { n: '奥拉迪亚' },
            BA: { n: '巴克乌' },
            BM: { n: '巴亚马雷' },
            BN: { n: '比斯特里察' },
            BO: { n: '博托沙尼' },
            BC: { n: '布加勒斯特' },
            BS: { n: '布拉索夫' },
            BL: { n: '布勒伊拉' },
            BZ: { n: '布泽乌' },
            DT: { n: '德罗贝塔-塞维林堡' },
            DE: { n: '德瓦' },
            TI: { n: '蒂米什瓦拉' },
            FO: { n: '福克沙尼' },
            GL: { n: '加拉茨' },
            GG: { n: '久尔久' },
            CT: { n: '康斯坦察' },
            DO: { n: '克拉约瓦' },
            CR: { n: '克勒拉希' },
            CN: { n: '克卢日纳波卡' },
            VI: { n: '勒姆尼库沃尔恰' },
            RE: { n: '雷希察' },
            MC: { n: '梅尔库里亚丘克' },
            PI: { n: '皮特什蒂' },
            PN: { n: '皮亚特拉尼亚姆茨' },
            PL: { n: '普洛耶什蒂' },
            SM: { n: '萨图·马雷' },
            SG: { n: '圣格奥尔基' },
            ST: { n: '斯拉蒂纳' },
            SB: { n: '斯洛博齐亚' },
            SU: { n: '苏恰瓦' },
            TA: { n: '特尔戈维什泰' },
            TM: { n: '特尔古穆列什' },
            TJ: { n: '特尔古日乌' },
            TU: { n: '图尔恰' },
            VA: { n: '瓦斯卢伊' },
            SO: { n: '锡比乌' },
            IS: { n: '雅西' },
            AD: { n: '亚厉山德里亚' },
            ZA: { n: '扎勒乌' }
        }
    },
    MDG: {
        n: '马达加斯加',
        0: {
            n: '',
            AS: { n: '安齐拉纳纳' },
            FN: { n: '菲亚纳兰楚阿' },
            MJ: { n: '马哈赞加' },
            AN: { n: '塔那那利佛' },
            TM: { n: '图阿马西拉' },
            TL: { n: '图利亚拉' }
        }
    },
    MDV: {
        n: '马尔代夫',
        0: {
            n: '',
            ADD: { n: '阿杜' },
            AAD: { n: '北阿里' },
            THD: { n: '北蒂拉杜马蒂' },
            MAD: { n: '北马洛斯马杜卢' },
            MLD: { n: '北米拉杜马杜卢' },
            NAD: { n: '北尼兰杜' },
            HAD: { n: '北苏瓦迪瓦' },
            FAA: { n: '法迪福卢' },
            FEA: { n: '费利杜' },
            FMU: { n: '福阿穆拉库' },
            HDH: { n: '哈杜马蒂' },
            KLH: { n: '科卢马杜卢' },
            MAL: { n: '马累' },
            MAA: { n: '马累岛' },
            MUA: { n: '穆拉库' },
            AAU: { n: '南阿里' },
            THU: { n: '南蒂拉杜马蒂' },
            MAU: { n: '南马洛斯马杜卢' },
            MLU: { n: '南米拉杜马杜卢' },
            NAU: { n: '南尼兰杜' },
            HAU: { n: '南苏瓦迪瓦' }
        }
    },
    MLT: { n: '马耳他', 0: { n: '', 0: { n: '' } } },
    MWI: {
        n: '马拉维',
        0: { n: '', N: { n: '北部区' }, S: { n: '南部区' }, C: { n: '中央区' } }
    },
    MYS: {
        n: '马来西亚',
        PH: {
            n: '槟榔屿',
            BWH: { n: '北海' },
            PEN: { n: '槟城' },
            BMJ: { n: '大山脚' },
            NTE: { n: '高渊' }
        },
        PS: { n: '玻璃市', KGR: { n: '加央' } },
        TR: {
            n: '丁加奴',
            KEM: { n: '甘马挽' },
            TGG: { n: '瓜拉丁加奴' },
            DGN: { n: '龙运' },
            MAR: { n: '马江' },
            SET: { n: '实兆' },
            HUL: { n: '乌鲁' },
            BES: { n: '勿述' }
        },
        KD: {
            n: '吉打',
            PGT: { n: '巴东得腊' },
            PEN: { n: '笨筒' },
            LGK: { n: '浮罗交怡' },
            KOR: { n: '哥打士打' },
            KPA: { n: '古邦巴素' },
            KMU: {
                n: '瓜拉姆达'
            },
            BLZ: { n: '华玲' },
            KLM: { n: '居林' },
            BMA: { n: '万拉峇鲁' }
        },
        KN: {
            n: '吉兰丹',
            PPU: { n: '巴西富地' },
            PMA: { n: '巴西马' },
            TMR: { n: '丹那美拉' },
            TUM: { n: '道北' },
            BAC: { n: '登卓' },
            KBR: { n: '哥打巴鲁' },
            KUG: { n: '瓜拉吉赖' },
            GMU: { n: '话望生' },
            MAC: { n: '马樟' },
            JEL: { n: '日里' }
        },
        KL: { n: '吉隆坡', KUL: { n: '吉隆坡' } },
        ML: {
            n: '马六甲',
            MEL: { n: '马六甲市' },
            AOG: { n: '亚罗牙也' },
            JAS: { n: '野新' }
        },
        LB: { n: '纳闽', LBU: { n: '纳闽' }, VIC: { n: '维多利亚' } },
        PG: {
            n: '彭亨',
            BER: { n: '百乐' },
            PEK: { n: '北根' },
            TEM: { n: '淡马鲁' },
            JER: { n: '而连突' },
            KUA: { n: '关丹' },
            CAH: { n: '金马仑高原' },
            RAU: { n: '劳勿' },
            KUL: { n: '立卑' },
            MAR: { n: '马兰' },
            BEN: { n: '文冬' },
            TOM: { n: '云冰' }
        },
        PK: {
            n: '霹雳',
            TAS: { n: '安顺' },
            TAM: { n: '丹绒马' },
            SSP: { n: '和丰' },
            LUM: { n: '紅土坎' },
            BGA: { n: '华都牙也' },
            KAR: { n: '江沙' },
            TPG: { n: '太平' },
            IPH: { n: '怡保' }
        },
        JH: {
            n: '柔佛',
            POW: { n: '笨珍' },
            MEP: { n: '丰盛港' },
            KTI: { n: '哥打丁宜' },
            KLA: { n: '居銮' },
            BAT: { n: '峇株巴辖' },
            MUA: { n: '麻坡' },
            SGM: { n: '昔加末' },
            JHB: { n: '新山' }
        },
        NS: {
            n: '森美兰',
            PDI: { n: '波德申' },
            TAI: { n: '淡边' },
            SRB: { n: '芙蓉' },
            KPI: { n: '瓜拉庇劳' },
            REM: { n: '林茂' },
            JEP: { n: '仁保' },
            JEL: { n: '日叻务' }
        },
        SB: {
            n: '沙巴',
            PAP: { n: '吧巴' },
            BEF: { n: '保佛' },
            BEL: { n: '比鲁兰' },
            PIT: { n: '必达士' },
            PMP: { n: '兵南邦' },
            TAB: { n: '担布南' },
            TEN: { n: '丹南' },
            TAW: { n: '斗湖' },
            TUR: { n: '斗亚兰' },
            BKI: { n: '哥打基纳巴鲁' },
            KMU: { n: '哥打马鲁都' },
            KEG: { n: '根地咬' },
            KUD: { n: '古达' },
            KBD: { n: '古打毛律' },
            KUN: { n: '古纳' },
            KPU: { n: '瓜拉班尤' },
            KBT: { n: '京那巴登岸' },
            RNU: { n: '兰脑' },
            LDU: { n: '拿笃' },
            NAB: { n: '纳巴湾' },
            SDK: { n: '山打根' },
            SPT: { n: '西比陶' },
            SMM: { n: '仙本那' }
        },
        SR: {
            n: '沙捞越',
            KCH: { n: '古晋' },
            KPI: { n: '加帛' },
            LMN: { n: '林梦' },
            MYY: { n: '美里' },
            BTU: { n: '民都鲁' },
            MKM: { n: '木胶' },
            BTG: { n: '木中' },
            SMH: { n: '三马拉汉' },
            SAM: { n: '斯里阿曼' },
            SAR: { n: '泗里街' },
            SBW: { n: '泗务' }
        },
        SL: {
            n: '雪兰莪',
            PJA: { n: '八打灵' },
            GOM: { n: '鹅麦' },
            KLG: { n: '瓜拉冷岳' },
            KSL: { n: '瓜拉雪兰莪' },
            SBM: { n: '沙白安南' },
            HUL: { n: '乌鲁冷岳' },
            HUS: { n: '乌鲁雪兰莪' },
            SEP: { n: '雪邦' }
        }
    },
    MLI: {
        n: '马里',
        0: {
            n: '',
            CD: { n: '巴马科首都区' },
            KD: { n: '基达尔' },
            GA: { n: '加奥' },
            KY: { n: '卡伊' },
            KL: { n: '库利科罗' },
            MP: { n: '莫普提' },
            SG: { n: '塞古' },
            TB: { n: '通布图' },
            SK: { n: '锡卡索' }
        }
    },
    MKD: { n: '马其顿', 0: { n: '', 0: { n: '' } } },
    MHL: { n: '马绍尔群岛', 0: { n: '', 0: { n: '' } } },
    MTQ: { n: '马提尼克', 0: { n: '', 0: { n: '' } } },
    MYT: { n: '马约特岛', 0: { n: '', 0: { n: '' } } },
    IMN: { n: '曼岛', 0: { n: '', 0: { n: '' } } },
    MUS: { n: '毛里求斯', 0: { n: '', 0: { n: '' } } },
    MRT: {
        n: '毛里塔尼亚',
        0: {
            n: '',
            AD: { n: '阿德拉尔' },
            AS: { n: '阿萨巴' },
            BR: { n: '卜拉克纳' },
            HG: { n: '东胡德' },
            GO: { n: '戈尔戈勒' },
            GM: { n: '吉迪马卡' },
            DN: { n: '努瓦迪布湾' },
            NO: { n: '努瓦克肖特特区' },
            TA: { n: '塔甘特' },
            TR: { n: '特拉扎' },
            TZ: { n: '提里斯-宰穆尔' },
            HC: { n: '西胡德' },
            IN: { n: '因希里' }
        }
    },
    USA: {
        n: '美国',
        AR: {
            n: '阿肯色',
            FYV: { n: '费耶特维尔' },
            FSM: { n: '史密斯堡' },
            LIT: { n: '小石城' }
        },
        AL: {
            n: '阿拉巴马',
            BHM: { n: '伯明罕' },
            MGM: { n: '蒙哥马利' },
            MOB: { n: '莫比尔' }
        },
        AK: {
            n: '阿拉斯加',
            ANC: { n: '安克雷奇' },
            FAI: { n: '费尔班克斯' },
            JNU: { n: '朱诺' }
        },
        ID: {
            n: '爱达荷',
            IDA: { n: '爱达荷福尔斯' },
            PIH: { n: '波卡特洛' },
            BOI: { n: '博伊西' },
            BLK: { n: '布莱克富特' },
            COE: { n: '科达伦' },
            LWS: { n: '刘易斯顿' },
            MJL: { n: '莫斯科' },
            ZMU: { n: '墨菲' },
            NPA: { n: '楠帕' },
            QKM: { n: '岂彻姆' },
            SVY: { n: '森瓦利' },
            YAF: { n: '亚美利加瀑布城' }
        },
        IA: {
            n: '爱荷华',
            DVN: { n: '达文波特' },
            DSM: { n: '得梅因' },
            CID: { n: '锡达拉皮兹' }
        },
        ND: {
            n: '北达科他',
            BIS: { n: '俾斯麦' },
            GFK: { n: '大福克斯' },
            FAR: { n: '法戈' },
            MOT: { n: '迈诺特' }
        },
        NC: {
            n: '北卡罗来纳',
            AEV: { n: '艾许维尔' },
            DHH: { n: '杜罕' },
            GBO: { n: '格林斯伯勒' },
            CHE: { n: '教堂山' },
            RAG: { n: '罗利' },
            RDU: { n: '洛利杜罕都会区' },
            CRQ: { n: '夏洛特' }
        },
        PA: {
            n: '宾夕法尼亚',
            AEW: { n: '阿伦敦' },
            PHL: { n: '费城' },
            PIT: { n: '匹兹堡' }
        },
        TX: {
            n: '德克萨斯',
            ELP: { n: '埃尔帕索' },
            AUS: { n: '奥斯汀' },
            DAL: { n: '达拉斯' },
            CRP: { n: '哥帕斯基斯蒂' },
            GLS: { n: '交维斯顿' },
            LRD: { n: '拉雷多' },
            TXC: { n: '麦亚伦' },
            SAT: { n: '圣安东尼奥' },
            HOU: { n: '休斯敦' }
        },
        OH: {
            n: '俄亥俄',
            DYT: { n: '代顿' },
            CZX: { n: '哥伦布' },
            CLE: { n: '克利夫兰' },
            TOL: { n: '托莱多' },
            CVG: { n: '辛辛那提' }
        },
        OK: {
            n: '俄克拉荷马',
            OKC: { n: '俄克拉荷马城' },
            OUN: { n: '诺曼' },
            TUL: { n: '塔尔萨' }
        },
        OR: {
            n: '俄勒冈',
            BZO: { n: '本德' },
            PDX: { n: '波特兰' },
            DLS: { n: '达尔斯' },
            DAC: { n: '达拉斯' },
            TLM: { n: '蒂拉穆克' },
            XFX: { n: '格兰茨帕斯' },
            HDX: { n: '胡德里弗' },
            CTR: { n: '火山口湖' },
            YCV: { n: '科瓦利斯' },
            COB: { n: '库斯贝' },
            MFR: { n: '梅德福' },
            SLE: { n: '塞勒姆' },
            STH: { n: '圣海伦斯' },
            SPY: { n: '斯普林菲尔德' },
            EUG: { n: '尤金' }
        },
        FL: {
            n: '佛罗里达',
            ORL: { n: '奥兰多' },
            EYW: { n: '基韦斯特' },
            JAX: { n: '杰克逊维尔' },
            CPV: { n: '卡纳维尔角' },
            FLL: { n: '罗德岱堡' },
            MIA: { n: '迈阿密' },
            PIE: { n: '圣彼德斯堡市' },
            TLH: { n: '塔拉哈西' },
            TPA: { n: '坦帕' }
        },
        VT: {
            n: '佛蒙特',
            BTV: { n: '伯灵顿' },
            RUT: { n: '拉特兰' },
            ZBR: { n: '南伯灵顿' }
        },
        DC: { n: '哥伦比亚特区', WAS: { n: '华盛顿哥伦比亚特区' } },
        WA: {
            n: '华盛顿',
            GEG: { n: '斯波坎' },
            TTW: { n: '塔科马' },
            SEA: { n: '西雅图' }
        },
        WY: {
            n: '怀俄明',
            EVD: { n: '埃文斯顿' },
            CPR: { n: '卡斯珀' },
            LAR: { n: '拉勒米' },
            RKS: { n: '罗克斯普林斯' },
            CYS: { n: '夏延' },
            SHR: { n: '谢里登' }
        },
        CA: {
            n: '加利福尼亚',
            SFO: { n: '旧金山' },
            LAX: { n: '洛杉矶' },
            SAN: { n: '圣迭戈' },
            SJC: { n: '圣何塞' }
        },
        KS: {
            n: '堪萨斯',
            ABZ: { n: '阿比林' },
            OVL: { n: '奥弗兰公园' },
            HCH: { n: '哈钦森' },
            KCK: { n: '堪萨斯城' },
            XIA: { n: '莱文沃思' },
            LWC: { n: '劳伦斯' },
            MHK: { n: '曼哈顿' },
            TOP: { n: '托皮卡' },
            ICT: { n: '威奇托' }
        },
        CT: {
            n: '康涅狄格',
            BDR: { n: '布里奇波特' },
            DAQ: { n: '达里恩' },
            GRH: { n: '格林尼治' },
            HFD: { n: '哈特福德' },
            XIN: { n: '米德尔顿' },
            HVN: { n: '纽黑文' },
            WPT: { n: '韦斯特波特' },
            WAT: { n: '沃特伯里' },
            NWT: { n: '新不列颠' }
        },
        CO: {
            n: '科罗拉多',
            ASE: { n: '阿斯彭' },
            AUX: { n: '奥罗拉' },
            WBU: { n: '博尔德' },
            GJT: { n: '大章克申' },
            DEN: { n: '丹佛' },
            FNL: { n: '柯林斯堡' },
            COS: { n: '科罗拉多斯普林斯' },
            VAC: { n: '韦尔' }
        },
        KY: {
            n: '肯塔基',
            LEX: { n: '列克星敦' },
            LUI: { n: '路易斯维尔' },
            OWB: { n: '欧文斯伯勒' }
        },
        LA: {
            n: '路易斯安那',
            BTR: { n: '巴吞鲁日' },
            SHV: { n: '什里夫波特' },
            MSY: { n: '新奥尔良' }
        },
        RI: {
            n: '罗德岛',
            PAW: { n: '波塔基特' },
            CQH: { n: '克兰斯顿' },
            NPO: { n: '纽波特' },
            PVD: { n: '普罗维登斯' },
            WST: { n: '韦斯特利' },
            SFN: { n: '文索基特' },
            UZO: { n: '沃威克' }
        },
        MD: {
            n: '马里兰',
            BAL: { n: '巴尔的摩' },
            GAI: { n: '盖瑟斯堡' },
            RKV: { n: '罗克维尔' }
        },
        MA: {
            n: '马萨诸塞',
            BZD: { n: '波士顿' },
            SFY: { n: '斯普林菲尔德' },
            ORH: { n: '伍斯特' }
        },
        MT: {
            n: '蒙大拿',
            BGS: { n: '比灵斯' },
            GTF: { n: '大瀑布村' },
            MSO: { n: '米苏拉' }
        },
        MO: {
            n: '密苏里',
            COV: { n: '哥伦比亚' },
            JEF: { n: '杰佛逊市' },
            MKC: { n: '堪萨斯城' },
            STL: { n: '圣路易斯' },
            SGF: { n: '斯普林菲尔德' }
        },
        MS: {
            n: '密西西比',
            BIX: { n: '比洛克西' },
            GPT: { n: '格尔夫波特' },
            GLH: { n: '格林维尔' },
            HBG: { n: '哈蒂斯堡' },
            JAN: { n: '杰克逊' },
            MEI: { n: '默里迪恩' },
            VKS: { n: '维克斯堡' }
        },
        MI: {
            n: '密歇根',
            ARB: { n: '安娜堡' },
            BTL: { n: '巴特尔克里克' },
            BCY: { n: '贝城' },
            GRR: { n: '大急流城' },
            DEO: { n: '迪尔伯恩' },
            DET: { n: '底特律' },
            FNT: { n: '弗林特' },
            WYD: { n: '怀恩多特' },
            AZO: { n: '卡拉马袓' },
            LAN: { n: '兰辛' },
            MKG: { n: '马斯基根' },
            PTK: { n: '庞菷亚克' },
            SGM: { n: '萨吉诺' },
            SSM: { n: '苏圣玛丽' },
            WAM: { n: '沃伦' },
            PHN: { n: '休伦港' }
        },
        ME: {
            n: '缅因',
            BNQ: { n: '班戈' },
            POL: { n: '波特兰' },
            QLW: { n: '刘易斯顿' }
        },
        MN: {
            n: '明尼苏达',
            RST: { n: '罗切斯特' },
            MES: { n: '明尼阿波利斯' },
            STP: { n: '圣保罗' }
        },
        SD: {
            n: '南达科他',
            ABK: { n: '阿伯丁' },
            RAP: { n: '拉皮德城' },
            FSD: { n: '苏福尔斯' }
        },
        SC: {
            n: '南卡罗来纳',
            NTS: { n: '北查尔斯顿' },
            CHS: { n: '查尔斯顿' },
            COV: { n: '哥伦比亚' }
        },
        NE: {
            n: '内布拉斯加',
            OMA: { n: '奥马哈' },
            XDE: { n: '贝尔维尤' },
            LNK: { n: '林肯' }
        },
        NV: {
            n: '内华达',
            EKO: { n: '埃尔科' },
            NVS: { n: '北拉斯维加斯' },
            VGI: { n: '弗吉尼亚城' },
            HNZ: { n: '亨德森' },
            CSN: { n: '卡森城' },
            LAS: { n: '拉斯维加斯' },
            RNO: { n: '里诺' },
            SPK: { n: '斯帕克斯' }
        },
        NY: {
            n: '纽约',
            FFO: { n: '布法罗' },
            ROC: { n: '罗切斯特' },
            QEE: { n: '纽约市' }
        },
        DE: {
            n: '特拉华',
            DOR: { n: '多佛' },
            NWK: { n: '纽瓦克' },
            ILG: { n: '威明顿' }
        },
        TN: {
            n: '田纳西',
            BSJ: { n: '布利斯托' },
            CHA: { n: '查塔努加' },
            TRI: { n: '金斯波特' },
            MEM: { n: '孟菲斯' },
            BNA: { n: '纳什维尔' },
            TYS: { n: '诺克斯维尔' },
            YTC: { n: '三城区' },
            MQY: { n: '士麦那' },
            RGI: { n: '斯普林希尔' },
            JCY: { n: '约翰逊城' }
        },
        WI: {
            n: '威斯康星',
            ATW: { n: '阿普尓顿' },
            OSH: { n: '奥什科什' },
            GBK: { n: '格林贝' },
            ENW: { n: '基诺沙' },
            LSE: { n: '拉克罗斯' },
            RAC: { n: '拉辛' },
            MTW: { n: '马尼托沃克' },
            QMD: { n: '迈迪逊' },
            MKE: { n: '密尔沃基' },
            EAU: { n: '欧克莱尓' },
            AUW: { n: '沃索' },
            SBM: { n: '希博伊根' }
        },
        VA: {
            n: '维吉尼亚',
            VAB: { n: '弗吉尼亚比奇' },
            ORF: { n: '诺福克' },
            HTW: { n: '切萨皮克' }
        },
        WV: {
            n: '西佛吉尼亚',
            CRW: { n: '查尔斯顿' },
            HNU: { n: '亨廷顿' },
            PKB: { n: '帕克斯堡' }
        },
        HI: {
            n: '夏威夷',
            KHH: { n: '凯卢阿' },
            HNL: { n: '檀香山' },
            ITO: { n: '希洛' }
        },
        NH: {
            n: '新罕布什尔',
            CON: { n: '康科德' },
            MHT: { n: '曼彻斯特' },
            ASH: { n: '纳舒厄' }
        },
        NM: {
            n: '新墨西哥',
            ABQ: { n: '阿尔伯克基' },
            LRU: { n: '拉斯克鲁塞斯' },
            ROW: { n: '罗斯韦尔' },
            SAF: { n: '圣菲' }
        },
        NJ: {
            n: '新泽西',
            NRK: { n: '纽瓦克' },
            PAT: { n: '帕特森' },
            JEC: { n: '泽西城' }
        },
        AZ: {
            n: '亚利桑那',
            PHX: { n: '凤凰城' },
            GDA: { n: '格兰代尔' },
            MQA: { n: '梅萨' },
            STZ: { n: '史卡兹代尔' },
            TPE: { n: '坦普' },
            TUC: { n: '图森' },
            YUM: { n: '优玛' }
        },
        IL: {
            n: '伊利诺斯',
            ALN: { n: '奥尔顿' },
            AUZ: { n: '奥罗拉' },
            BLO: { n: '布卢明顿' },
            DVI: { n: '丹维尓' },
            DEK: { n: '迪卡尔布' },
            DEC: { n: '迪凯持' },
            ESL: { n: '东圣路易斯' },
            CMI: { n: '厄巴纳-香槟' },
            GSU: { n: '盖尔斯堡' },
            MDH: { n: '卡本代尔' },
            RKI: { n: '罗克艾兰' },
            RFD: { n: '罗克福德' },
            NOM: { n: '诺黙尔' },
            PLA: { n: '皮奥里亚' },
            CRA: { n: '森特勒利亚' },
            SPI: { n: '斯普林菲尔德' },
            UGN: { n: '沃其根' },
            CHI: { n: '芝加哥' }
        },
        IN: {
            n: '印第安那',
            EVV: { n: '埃文斯维尔' },
            FWA: { n: '韦恩堡' },
            IND: { n: '印第安纳波利斯' }
        },
        UT: {
            n: '犹他',
            OGD: { n: '奥格登' },
            LTJ: { n: '雷登' },
            OEU: { n: '欧仁' },
            PAC: { n: '帕克城' },
            PVU: { n: '普罗沃' },
            SGU: { n: '圣乔治' },
            WVC: { n: '西瓦利城' },
            SLC: { n: '盐湖城' }
        },
        GA: {
            n: '佐治亚',
            AUT: { n: '奥古斯塔' },
            CZX: { n: '哥伦布' },
            MCN: { n: '梅肯' },
            SAV: { n: '沙瓦纳' },
            TAT: { n: '亚特兰大' }
        }
    },
    ASM: {
        n: '美属萨摩亚',
        0: {
            n: '',
            AAN: { n: '阿纳' },
            ATU: { n: '阿图阿' },
            AIT: { n: '艾加伊勒泰' },
            FAA: { n: '法塞莱莱阿加' },
            GFG: { n: '加盖福毛加' },
            GMG: { n: '加加埃毛加' },
            PAL: { n: '帕劳利' },
            SAT: { n: '萨图帕伊泰阿' },
            SAV: { n: '萨瓦伊岛' },
            TUA: { n: '图阿马萨加' },
            VAF: { n: '瓦奥福诺蒂' },
            VAI: { n: '韦西加诺' },
            UPO: { n: '乌波卢岛' }
        }
    },
    UMI: { n: '美属外岛', 0: { n: '', 0: { n: '' } } },
    MNG: {
        n: '蒙古',
        0: {
            n: '',
            69: { n: '巴彦洪格尔' },
            71: { n: '巴彦乌勒盖' },
            67: { n: '布尔干' },
            37: { n: '达尔汗乌勒' },
            61: { n: '东方' },
            63: { n: '东戈壁' },
            35: { n: '鄂尔浑' },
            65: { n: '戈壁阿尔泰' },
            64: { n: '戈壁苏木贝尔' },
            73: { n: '后杭爱' },
            43: { n: '科布多' },
            39: { n: '肯特' },
            41: { n: '库苏古尔' },
            UMN: { n: '南戈壁' },
            UVO: { n: '前杭爱' },
            49: { n: '色楞格' },
            51: { n: '苏赫巴托尔' },
            46: { n: '乌布苏' },
            1: { n: '乌兰巴托市' },
            57: { n: '扎布汗' },
            59: { n: '中戈壁' },
            47: { n: '中央' }
        }
    },
    MSR: { n: '蒙特塞拉特', 0: { n: '', 0: { n: '' } } },
    BGD: {
        n: '孟加拉',
        0: {
            n: '',
            DAC: { n: '达卡' },
            CGP: { n: '吉大港' },
            KHL: { n: '库尔纳' }
        }
    },
    FSM: { n: '密克罗尼西亚', 0: { n: '', 0: { n: '' } } },
    PER: {
        n: '秘鲁',
        0: {
            n: '',
            AR: { n: '阿雷基帕' },
            AP: { n: '阿普里马克' },
            AY: { n: '阿亚库乔' },
            AN: { n: '安卡什' },
            JUL: { n: '胡利亚卡' },
            JU: { n: '胡宁' },
            CJ: { n: '卡哈马卡' },
            CL: { n: '卡亚俄' },
            CU: { n: '库斯科' },
            LD: { n: '拉利伯塔德' },
            LY: { n: '兰巴耶克' },
            LI: { n: '利马' },
            LO: { n: '洛雷托' },
            MD: { n: '马德雷德迪奥斯' },
            MO: { n: '莫克瓜' },
            PA: { n: '帕斯科' },
            PI: { n: '皮乌拉' },
            PU: { n: '普诺' },
            CHM: { n: '钦博特' },
            CHI: { n: '钦查阿尔塔' },
            SM: { n: '圣马丁' },
            SUL: { n: '苏拉纳' },
            TA: { n: '塔克纳' },
            TU: { n: '通贝斯' },
            HO: { n: '瓦努科' },
            HV: { n: '万卡维利卡' },
            UC: { n: '乌卡亚利' },
            AM: { n: '亚马孙' },
            IC: { n: '伊卡' }
        }
    },
    MMR: {
        n: '缅甸',
        0: {
            n: '',
            BG: { n: '勃固省' },
            SH: { n: '掸邦' },
            TN: { n: '德林达依省' },
            KN: { n: '克伦邦' },
            KC: { n: '克钦邦' },
            KH: { n: '克耶邦' },
            MG: { n: '马圭省' },
            MD: { n: '曼德勒省' },
            MN: { n: '孟邦' },
            CH: { n: '钦邦' },
            RK: { n: '若开邦' },
            SG: { n: '实皆省' },
            YG: { n: '仰光省' },
            AY: { n: '伊洛瓦底省' }
        }
    },
    MDA: { n: '摩尔多瓦', 0: { n: '', 0: { n: '' } } },
    MAR: {
        n: '摩洛哥',
        0: {
            n: '',
            TGR: { n: '丹吉尔' },
            TET: { n: '得土安' },
            FES: { n: '非斯' },
            CBL: { n: '卡萨布兰卡' },
            RSA: { n: '拉巴特' },
            MRK: { n: '马拉喀什' },
            MKN: { n: '梅克内斯' },
            OUJ: { n: '乌季达' },
            WSH: { n: '西撒哈拉' }
        }
    },
    MCO: { n: '摩纳哥', 0: { n: '', 0: { n: '' } } },
    MOZ: { n: '莫桑比克', 0: { n: '', 0: { n: '' } } },
    MEX: {
        n: '墨西哥',
        0: {
            n: '',
            AGU: { n: '阿瓜斯卡连斯特' },
            ACA: { n: '阿卡普尔科' },
            HMO: { n: '埃莫西约' },
            CAM: { n: '埃佩切' },
            OBR: { n: '奥夫雷贡城' },
            ORI: { n: '奥里萨巴' },
            VHM: { n: '巴利城' },
            PVR: { n: '巴亚尔塔港' },
            VSA: { n: '比利亚埃尔莫萨' },
            PRH: { n: '波萨里卡' },
            TIJ: { n: '蒂华纳' },
            DUR: { n: '杜兰戈' },
            ESE: { n: '恩塞纳达' },
            GDL: { n: '瓜达拉哈拉' },
            GUA: { n: '瓜纳华托' },
            JAL: { n: '哈拉帕' },
            JUZ: { n: '华雷斯' },
            BJU: { n: '华雷斯港' },
            CAR: { n: '卡门' },
            COL: { n: '科利马' },
            QUE: { n: '克雷塔罗' },
            CVC: { n: '库埃纳瓦卡' },
            CUL: { n: '库利阿坎' },
            COA: { n: '夸察夸拉克斯' },
            LAP: { n: '拉巴斯' },
            LEN: { n: '莱昂' },
            REX: { n: '雷诺萨' },
            LMM: { n: '洛斯莫奇斯' },
            MZT: { n: '马萨特兰' },
            MAM: { n: '马塔莫罗斯' },
            MID: { n: '梅里达' },
            LOV: { n: '蒙克洛瓦' },
            MTY: { n: '蒙特雷' },
            MLM: { n: '莫雷利亚' },
            MEX: { n: '墨西哥城' },
            MXL: { n: '墨西卡利' },
            NOG: { n: '诺加莱斯' },
            PAC: { n: '帕丘卡' },
            PUE: { n: '普埃布拉' },
            CHI: { n: '奇尔潘辛戈' },
            CHH: { n: '奇瓦瓦' },
            CTM: { n: '切图马尔' },
            SLW: { n: '萨尔蒂约' },
            ZAC: { n: '萨卡特卡斯' },
            CLY: { n: '塞拉亚' },
            SLP: { n: '圣路易斯波托亚' },
            TAP: { n: '塔帕丘拉' },
            TAM: { n: '坦皮科' },
            TLA: { n: '特拉斯卡拉' },
            TPQ: { n: '特皮克' },
            TCN: { n: '特瓦坎' },
            TGZ: { n: '图斯特拉-古铁雷斯' },
            TRC: { n: '托雷翁' },
            TLC: { n: '托卢卡' },
            OAX: { n: '瓦哈卡' },
            VIC: { n: '维多利亚城' },
            VER: { n: '韦拉克鲁斯' },
            UPN: { n: '乌鲁阿潘' },
            NLE: { n: '新拉雷多' },
            IRP: { n: '伊拉普阿托' }
        }
    },
    NAM: {
        n: '纳米比亚',
        0: {
            n: '',
            ER: { n: '埃龙戈' },
            OW: { n: '奥汉圭纳' },
            KV: { n: '奥卡万戈' },
            OK: { n: '奥马赫科' },
            OT: { n: '奥姆沙蒂' },
            OJ: { n: '奥乔宗蒂约巴' },
            ON: { n: '奥沙纳' },
            OO: { n: '奥希科托' },
            HA: { n: '哈达普' },
            KH: { n: '霍马斯' },
            KR: { n: '卡拉斯' },
            CA: { n: '卡普里维' },
            KU: { n: '库内内' }
        }
    },
    ZAF: {
        n: '南非',
        0: {
            n: '',
            UTN: { n: '阿平顿' },
            MAY: { n: '艾利弗山' },
            PZB: { n: '彼德马里茨堡' },
            PTG: { n: '彼德斯堡' },
            PRY: { n: '比勒陀利亚' },
            BIY: { n: '比索' },
            BDD: { n: '布雷达斯多普' },
            BFN: { n: '布隆方丹' },
            BHT: { n: '布隆克斯特斯普利特' },
            DAA: { n: '德阿尔' },
            DUR: { n: '德班' },
            DUN: { n: '邓迪' },
            BAE: { n: '东巴克利' },
            ELS: { n: '东伦敦' },
            VRU: { n: '弗雷堡' },
            VGG: { n: '弗里尼欣' },
            GBD: { n: '格罗布莱斯达尔' },
            GIY: { n: '基雅尼' },
            KIM: { n: '金伯利' },
            CPT: { n: '开普敦' },
            KXE: { n: '克莱克斯多普' },
            KMH: { n: '库鲁曼' },
            UTW: { n: '昆士敦' },
            LAY: { n: '莱迪史密斯' },
            RFT: { n: '兰德方丹' },
            RCB: { n: '理查兹湾' },
            RSB: { n: '利斯滕堡' },
            MDB: { n: '米德尔堡' },
            MZQ: { n: '姆库泽' },
            MOO: { n: '穆里斯堡' },
            NLP: { n: '内尔斯普雷特' },
            NYL: { n: '尼尔斯特隆' },
            NCS: { n: '纽卡斯尔' },
            GRJ: { n: '乔治' },
            SAS: { n: '萨索尔堡' },
            ZEC: { n: '瑟孔达' },
            IXO: { n: '特克索波' },
            TPB: { n: '特隆普斯堡' },
            SBU: { n: '跳羚' },
            TLH: { n: '图拉马哈谢' },
            THY: { n: '托霍延杜' },
            WSH: { n: '韦茨肖克' },
            WEL: { n: '韦尔科姆' },
            ULD: { n: '乌伦迪' },
            UTT: { n: '乌姆塔塔' },
            WOR: { n: '伍斯特' },
            BEW: { n: '西博福特' },
            PSS: { n: '谢普斯通港' },
            PLZ: { n: '伊丽莎白港' },
            JNB: { n: '约翰内斯堡' }
        }
    },
    ATA: { n: '南极洲', 0: { n: '', 0: { n: '' } } },
    SGS: { n: '南乔治亚和南桑德威奇群岛', 0: { n: '', 0: { n: '' } } },
    NRU: { n: '瑙鲁', 0: { n: '', 0: { n: '' } } },
    NPL: {
        n: '尼泊尔',
        0: {
            n: '',
            BA: { n: '巴格马蒂' },
            DH: { n: '道拉吉里' },
            GA: { n: '甘达基' },
            KO: { n: '戈西' },
            KA: { n: '格尔纳利' },
            JA: { n: '贾纳克布尔' },
            RA: { n: '拉布蒂' },
            LU: { n: '蓝毗尼' },
            MA: { n: '马哈卡利' },
            ME: { n: '梅吉' },
            NA: { n: '纳拉亚尼' },
            BH: { n: '佩里' },
            SA: { n: '萨加玛塔' },
            SE: { n: '塞蒂' }
        }
    },
    NIC: {
        n: '尼加拉瓜',
        0: {
            n: '',
            ES: { n: '埃斯特利' },
            AN: { n: '北大西洋' },
            BO: { n: '博阿科' },
            GR: { n: '格拉纳达' },
            CA: { n: '卡拉索' },
            LE: { n: '莱昂' },
            RV: { n: '里瓦斯' },
            MD: { n: '马德里斯' },
            MN: { n: '马那瓜' },
            MS: { n: '马萨亚' },
            MT: { n: '马塔加尔帕' },
            AS: { n: '南大西洋' },
            CD: { n: '奇南德加' },
            CT: { n: '琼塔莱斯' },
            SJ: { n: '圣胡安河' },
            JI: { n: '希诺特加' },
            NS: { n: '新塞哥维亚' }
        }
    },
    NER: {
        n: '尼日尔',
        0: {
            n: '',
            AJY: { n: '阿加德兹' },
            DIF: { n: '迪法' },
            TIL: { n: '蒂拉贝里' },
            DSS: { n: '多索' },
            ZND: { n: '津德尔' },
            MFQ: { n: '马拉迪' },
            NIM: { n: '尼亚美市' },
            THZ: { n: '塔瓦' }
        }
    },
    NGA: {
        n: '尼日利亚',
        0: {
            n: '',
            ABV: { n: '阿比亚' },
            OGB: { n: '奥博莫绍' },
            KAN: { n: '卡诺' },
            LOS: { n: '拉各斯' },
            IBA: { n: '伊巴丹' }
        }
    },
    NIU: { n: '纽埃', 0: { n: '', 0: { n: '' } } },
    NOR: {
        n: '挪威',
        0: {
            n: '',
            2: { n: '阿克什胡斯' },
            5: { n: '奥普兰' },
            3: { n: '奥斯陆市' },
            17: { n: '北特伦德拉格' },
            6: { n: '布斯克吕' },
            9: { n: '东阿格德尔' },
            1: { n: '东福尔' },
            20: { n: '芬马克' },
            4: { n: '海德马克' },
            12: { n: '霍达兰' },
            11: { n: '罗加兰' },
            15: { n: '默勒－鲁姆斯达尔' },
            16: { n: '南特伦德拉格' },
            18: { n: '诺尔兰' },
            14: { n: '松恩－菲尤拉讷' },
            8: { n: '泰勒马克' },
            19: { n: '特罗姆斯' },
            10: { n: '西阿格德尔' },
            7: { n: '西福尔' }
        }
    },
    NFK: { n: '诺福克', 0: { n: '', 0: { n: '' } } },
    PLW: { n: '帕劳群岛', 0: { n: '', 0: { n: '' } } },
    PCN: { n: '皮特凯恩', 0: { n: '', 0: { n: '' } } },
    PRT: {
        n: '葡萄牙',
        0: {
            n: '',
            ALL: { n: '滨海阿连特茹' },
            PLT: { n: '滨海皮尼亚尔' },
            VDP: { n: '波尔图' },
            MDR: { n: '杜罗' },
            EDV: { n: '恩特拉杜罗伏日' },
            FAO: { n: '法鲁' },
            FUN: { n: '丰沙尔' },
            CAV: { n: '卡瓦多' },
            CLB: { n: '科瓦贝拉' },
            LIS: { n: '里斯本' },
            LTE: { n: '利巴特茹' },
            MTE: { n: '梅地奥特茹' },
            MLI: { n: '米尼奥-利马' },
            BIN: { n: '内贝拉北' },
            BIS: { n: '内贝拉南' },
            PIN: { n: '内皮尼亚尔北' },
            PIS: { n: '内皮尼亚尔南' },
            PDL: { n: '蓬塔德尔加达' },
            PSE: { n: '塞图巴尔半岛' },
            SES: { n: '山后' },
            AAT: { n: '上阿连特茹' },
            ATM: { n: '上特拉斯山' },
            TAM: { n: '塔梅加' },
            AES: { n: '万福' },
            OES: { n: '西部' },
            BAL: { n: '下阿连特茹' },
            BVO: { n: '下伏日' },
            BMO: { n: '下蒙德古' },
            ALC: { n: '中阿连特茹' }
        }
    },
    GEO: { n: '乔治亚', 0: { n: '', 0: { n: '' } } },
    JPN: {
        n: '日本',
        0: {
            n: '',
            38: { n: '爱媛' },
            23: { n: '爱知' },
            1: { n: '北海道' },
            28: { n: '兵库' },
            47: { n: '冲绳' },
            8: { n: '茨城' },
            27: { n: '大阪' },
            44: { n: '大分' },
            32: { n: '岛根' },
            36: { n: '徳岛' },
            13: { n: '东京' },
            7: { n: '福岛' },
            40: { n: '福冈' },
            18: { n: '福井' },
            16: { n: '富山' },
            33: { n: '冈山' },
            39: { n: '高知' },
            4: { n: '宮城' },
            45: { n: '宫崎' },
            34: { n: '广岛' },
            30: { n: '和歌山' },
            26: { n: '京都' },
            22: { n: '静冈' },
            9: { n: '枥木' },
            46: { n: '鹿儿岛' },
            29: { n: '奈良' },
            31: { n: '鸟取' },
            21: { n: '岐阜' },
            11: { n: '埼玉' },
            12: { n: '千叶' },
            2: { n: '青森' },
            5: { n: '秋田' },
            10: { n: '群马' },
            24: { n: '三重' },
            35: { n: '山口' },
            19: { n: '山梨' },
            6: { n: '山形' },
            14: { n: '神奈川' },
            17: { n: '石川' },
            37: { n: '香川' },
            15: { n: '新潟' },
            43: { n: '熊本' },
            3: { n: '岩手' },
            42: { n: '长崎' },
            20: { n: '长野' },
            25: { n: '滋贺' },
            41: { n: '佐贺' }
        }
    },
    SWE: {
        n: '瑞典',
        0: {
            n: '',
            BD: { n: '北博滕' },
            K: { n: '布莱金厄' },
            DLN: { n: '达拉纳' },
            UGL: { n: '东约特兰' },
            T: { n: '厄勒布鲁' },
            I: { n: '哥得兰' },
            N: { n: '哈兰' },
            H: { n: '卡尔马' },
            G: { n: '克鲁努贝里' },
            D: { n: '南曼兰' },
            AB: { n: '斯德哥尔摩' },
            M: { n: '斯科耐' },
            S: { n: '韦姆兰' },
            C: { n: '乌普萨拉' },
            AC: { n: '西博滕' },
            U: { n: '西曼兰' },
            Y: { n: '西诺尔兰' },
            O: { n: '西约特兰' },
            F: { n: '延雪平' },
            X: { n: '耶夫勒堡' },
            Z: { n: '耶姆特兰' }
        }
    },
    CHE: {
        n: '瑞士',
        0: {
            n: '',
            AG: { n: '阿尔高' },
            BS: { n: '巴塞尔城市' },
            BL: { n: '巴塞尔乡村' },
            BE: { n: '伯尔尼' },
            ZG: { n: '楚格' },
            FR: { n: '弗里堡' },
            GL: { n: '格拉鲁斯' },
            GR: { n: '格劳宾登' },
            LU: { n: '卢塞恩' },
            LA: { n: '洛桑' },
            NE: { n: '纳沙泰尔' },
            AI: { n: '内阿彭策尔' },
            GE: { n: '日内瓦' },
            JU: { n: '汝拉' },
            SH: { n: '沙夫豪森' },
            OW: { n: '上瓦尔登' },
            SG: { n: '圣加仑' },
            SZ: { n: '施维茨' },
            ZH: { n: '苏黎世' },
            SO: { n: '索洛图恩' },
            TI: { n: '提契诺' },
            TG: { n: '图尔高' },
            VS: { n: '瓦莱' },
            AR: { n: '外阿彭策尔' },
            VD: { n: '沃' },
            UR: { n: '乌里' },
            NW: { n: '下瓦尔登' }
        }
    },
    SLV: {
        n: '萨尔瓦多',
        0: {
            n: '',
            APO: { n: '阿波帕' },
            AH: { n: '阿瓦查潘' },
            LI: { n: '滨海' },
            CH: { n: '查拉特南戈' },
            DE: { n: '德尔加多' },
            KN: { n: '基埃-恩特姆' },
            CA: { n: '卡瓦尼亚斯' },
            CU: { n: '库斯卡特兰' },
            PZ: { n: '拉巴斯' },
            LB: { n: '拉利伯塔德' },
            UN: { n: '拉乌尼翁' },
            MEJ: { n: '梅基卡诺斯' },
            MO: { n: '莫拉桑' },
            SA: { n: '圣安娜' },
            SM: { n: '圣米格尔' },
            SS: { n: '圣萨尔瓦多' },
            SV: { n: '圣维森特' },
            SO: { n: '松索纳特' },
            SOY: { n: '索亚潘戈' },
            WN: { n: '韦莱-恩萨斯' },
            US: { n: '乌苏卢坦' },
            IL: { n: '伊洛潘戈' },
            CS: { n: '中南' }
        }
    },
    WSM: { n: '萨摩亚', 0: { n: '', 0: { n: '' } } },
    SCG: {
        n: '塞尔维亚,黑山',
        0: {
            n: '',
            BEG: { n: '贝尔格莱德' },
            POD: { n: '波德戈里察' },
            KGV: { n: '克拉古涅瓦茨' },
            INI: { n: '尼什' },
            NVS: { n: '诺维萨德' },
            PRN: { n: '普里什蒂纳' },
            SUB: { n: '苏博蒂察' },
            ZEM: { n: '泽蒙' }
        }
    },
    SLE: {
        n: '塞拉利昂',
        0: {
            n: '',
            N: { n: '北部' },
            E: { n: '东部' },
            S: { n: '南部' },
            W: { n: '西部区' }
        }
    },
    SEN: {
        n: '塞内加尔',
        0: {
            n: '',
            DA: { n: '达喀尔' },
            FA: { n: '法蒂克' },
            ZI: { n: '济金绍尔' },
            TH: { n: '捷斯' },
            DI: { n: '久尔贝勒' },
            KA: { n: '考拉克' },
            KO: { n: '科尔达' },
            LO: { n: '卢加' },
            MA: { n: '马塔姆' },
            SL: { n: '圣路易' },
            TA: { n: '坦巴昆达' }
        }
    },
    CYP: {
        n: '塞浦路斯',
        0: {
            n: '',
            4: { n: '法马古斯塔' },
            6: { n: '凯里尼亚' },
            3: { n: '拉纳卡' },
            2: { n: '利马索尔' },
            1: { n: '尼科西亚' },
            5: { n: '帕福斯' }
        }
    },
    SYC: { n: '塞舌尔', 0: { n: '', 0: { n: '' } } },
    SAU: {
        n: '沙特阿拉伯',
        0: {
            n: '',
            ARA: { n: '阿尔阿尔' },
            AHB: { n: '艾卜哈' },
            BH: { n: '巴哈' },
            BUR: { n: '布赖代' },
            DAM: { n: '达曼' },
            HBT: { n: '哈费尔巴廷' },
            HL: { n: '哈伊勒' },
            KMX: { n: '海米斯穆谢特' },
            AKH: { n: '海耶' },
            HFF: { n: '胡富夫' },
            JED: { n: '吉达' },
            JZ: { n: '吉赞' },
            RD: { n: '利雅得' },
            MED: { n: '麦地那' },
            ML: { n: '麦加' },
            MBR: { n: '姆巴拉兹' },
            NR: { n: '纳季兰' },
            SAK: { n: '塞卡卡' },
            TB: { n: '塔布克' },
            TAR: { n: '塔伊夫' },
            YNB: { n: '延布' },
            JBI: { n: '朱拜勒' }
        }
    },
    CXR: { n: '圣诞岛', 0: { n: '', 0: { n: '' } } },
    STP: { n: '圣多美和普林西比', 0: { n: '', 0: { n: '' } } },
    SHN: { n: '圣赫勒拿', 0: { n: '', 0: { n: '' } } },
    KNA: { n: '圣基茨和尼维斯', 0: { n: '', 0: { n: '' } } },
    LCA: { n: '圣卢西亚', 0: { n: '', 0: { n: '' } } },
    SMR: { n: '圣马力诺', 0: { n: '', 0: { n: '' } } },
    SPM: { n: '圣皮埃尔和米克隆群岛', 0: { n: '', 0: { n: '' } } },
    VCT: { n: '圣文森特和格林纳丁斯', 0: { n: '', 0: { n: '' } } },
    LKA: {
        n: '斯里兰卡',
        0: {
            n: '',
            ADP: { n: '阿努拉德普勒' },
            AMP: { n: '安帕赖' },
            BAD: { n: '巴杜勒' },
            BTC: { n: '拜蒂克洛' },
            POL: { n: '波隆纳鲁沃' },
            HBA: { n: '汉班托特' },
            KIL: { n: '基里诺奇' },
            GAL: { n: '加勒' },
            GAM: { n: '加姆珀哈' },
            JAF: { n: '贾夫纳' },
            KLT: { n: '卡卢特勒' },
            KEG: { n: '凯格勒' },
            KAN: { n: '康提' },
            CMB: { n: '科伦坡' },
            KUR: { n: '库鲁内格勒' },
            RAT: { n: '拉特纳普勒' },
            MAN: { n: '马纳尔' },
            MAT: { n: '马特莱' },
            MAA: { n: '马特勒' },
            MON: { n: '莫讷勒格勒' },
            MUL: { n: '穆莱蒂武' },
            NUE: { n: '努沃勒埃利耶' },
            PUT: { n: '普塔勒姆' },
            TRR: { n: '亭可马里' },
            VAV: { n: '瓦武尼亚' }
        }
    },
    SVK: {
        n: '斯洛伐克',
        0: {
            n: '',
            BBY: { n: '班斯卡-比斯特里察' },
            BTS: { n: '布拉迪斯拉发' },
            KOR: { n: '科希策' },
            NRA: { n: '尼特拉' },
            POV: { n: '普雷绍夫' },
            RIL: { n: '日利纳' },
            TNA: { n: '特尔纳瓦' },
            TRE: { n: '特伦钦' }
        }
    },
    SVN: {
        n: '斯洛文尼亚',
        0: {
            n: '',
            OKR: { n: '奥巴尔诺-克拉' },
            OSR: { n: '奥斯雷德涅斯洛文' },
            POD: { n: '波德拉夫' },
            POM: { n: '波穆尔' },
            DLJ: { n: '多雷尼' },
            GSZ: { n: '戈雷尼' },
            GSK: { n: '戈里' },
            KOR: { n: '科洛' },
            NKR: { n: '诺特拉尼' },
            SAV: { n: '萨维尼' },
            SPO: { n: '斯波德涅波萨夫' },
            ZAS: { n: '扎萨夫' }
        }
    },
    SJM: { n: '斯瓦尔巴和扬马廷', 0: { n: '', 0: { n: '' } } },
    SWZ: { n: '斯威士兰', 0: { n: '', 0: { n: '' } } },
    SDN: {
        n: '苏丹',
        0: {
            n: '',
            ASH: { n: '北部' },
            SIS: { n: '赤道' },
            SDA: { n: '达尔富尔' },
            SHA: { n: '东部' },
            SBG: { n: '加扎勒河' },
            KRT: { n: '喀土穆' },
            GKU: { n: '科尔多凡' },
            ANB: { n: '上尼罗' },
            WDH: { n: '中部' }
        }
    },
    SUR: {
        n: '苏里南',
        0: {
            n: '',
            BR: { n: '布罗科蓬多' },
            CR: { n: '科罗尼' },
            CM: { n: '科默韦讷' },
            MA: { n: '马罗韦讷' },
            NI: { n: '尼克里' },
            PA: { n: '帕拉' },
            PM: { n: '帕拉马里博' },
            SA: { n: '萨拉马卡' },
            WA: { n: '瓦尼卡' },
            SI: { n: '西帕里韦尼' }
        }
    },
    SLB: {
        n: '所罗门群岛',
        0: {
            n: '',
            GC: { n: '瓜达尔卡纳尔' },
            HO: { n: '霍尼亚拉' },
            RB: { n: '拉纳尔和贝罗纳' },
            MK: { n: '马基拉' },
            ML: { n: '马莱塔' },
            CH: { n: '乔伊索' },
            TM: { n: '泰莫图' },
            WE: { n: '西部' },
            IS: { n: '伊萨贝尔' },
            CE: { n: '中部群岛' }
        }
    },
    SOM: { n: '索马里', 0: { n: '', 0: { n: '' } } },
    TJK: {
        n: '塔吉克斯坦',
        0: {
            n: '',
            DYU: { n: '杜尚别' },
            KHO: { n: '霍罗格' },
            KAN: { n: '卡尼巴达姆' },
            KOF: { n: '科法尔尼洪' },
            KHU: { n: '苦盏' },
            KTJ: { n: '库尔干-秋别' },
            KLB: { n: '库洛布' },
            RGU: { n: '洛贡' },
            NUR: { n: '努雷克' },
            PJK: { n: '彭吉肯特' },
            SBA: { n: '萨班特' },
            TBS: { n: '塔博沙尔' },
            TSZ: { n: '图尔孙扎德' },
            UTJ: { n: '乌拉秋别' },
            ISF: { n: '伊斯法拉' }
        }
    },
    THA: {
        n: '泰国',
        0: {
            n: '',
            37: { n: '安纳乍能' },
            77: { n: '巴蜀' },
            13: { n: '巴吞他尼' },
            25: { n: '巴真' },
            71: { n: '北碧' },
            19: { n: '北标' },
            94: { n: '北大年' },
            11: { n: '北揽' },
            60: { n: '北榄坡' },
            24: { n: '北柳' },
            76: { n: '碧差汶' },
            93: { n: '博达伦' },
            18: { n: '猜那' },
            36: { n: '猜也奔' },
            53: { n: '程逸' },
            86: { n: '春蓬' },
            20: { n: '春武里' },
            63: { n: '达' },
            23: { n: '达叻' },
            14: { n: '大城' },
            92: { n: '董里' },
            78: { n: '佛丕' },
            73: { n: '佛统' },
            62: { n: '甘烹碧' },
            15: { n: '红统' },
            16: { n: '华富里' },
            46: { n: '加拉信' },
            81: { n: '甲米' },
            22: { n: '尖竹汶' },
            40: { n: '孔敬' },
            21: { n: '拉农' },
            43: { n: '廊开' },
            39: { n: '廊莫那浦' },
            70: { n: '叻丕' },
            42: { n: '黎' },
            45: { n: '黎逸' },
            74: { n: '龙仔厝' },
            85: { n: '罗勇' },
            80: { n: '洛坤' },
            44: { n: '玛哈沙拉堪' },
            10: { n: '曼谷' },
            49: { n: '莫达汉' },
            26: { n: '那空那育' },
            48: { n: '那空帕农' },
            55: { n: '难' },
            51: { n: '南奔' },
            12: { n: '暖武里' },
            54: { n: '帕' },
            56: { n: '帕尧' },
            82: { n: '攀牙' },
            65: { n: '彭世洛' },
            66: { n: '披集' },
            83: { n: '普吉' },
            57: { n: '清莱' },
            50: { n: '清迈' },
            47: { n: '色军' },
            91: { n: '沙敦' },
            27: { n: '沙缴' },
            33: { n: '四色菊' },
            90: { n: '宋卡' },
            64: { n: '素可泰' },
            84: { n: '素叻' },
            32: { n: '素林' },
            72: { n: '素攀武里' },
            96: { n: '陶公' },
            41: { n: '乌隆' },
            61: { n: '乌泰他尼' },
            34: { n: '乌汶' },
            31: { n: '武里南' },
            17: { n: '信武里' },
            35: { n: '耶梭通' },
            95: { n: '也拉' },
            58: { n: '夜丰颂' },
            75: { n: '夜功' }
        }
    },
    TZA: {
        n: '坦桑尼亚',
        0: {
            n: '',
            AR: { n: '阿鲁沙' },
            PN: { n: '奔巴北' },
            PS: { n: '奔巴南' },
            PW: { n: '滨海' },
            DS: { n: '达累斯萨拉姆' },
            DO: { n: '多多马' },
            KI: { n: '基戈马' },
            KA: { n: '卡盖拉' },
            LN: { n: '林迪' },
            RK: { n: '鲁夸' },
            RV: { n: '鲁伍马' },
            MR: { n: '马腊' },
            MY: { n: '曼亚拉' },
            MO: { n: '莫洛戈罗' },
            MB: { n: '姆贝亚' },
            MT: { n: '姆特瓦拉' },
            MW: { n: '姆万扎' },
            KJ: { n: '乞力马扎罗' },
            ZN: { n: '桑给巴尔' },
            UN: { n: '桑给巴尔北' },
            US: { n: '桑给巴尔南' },
            MM: { n: '桑给巴尔市和西' },
            TB: { n: '塔波拉' },
            TN: { n: '坦噶' },
            SI: { n: '辛吉达' },
            SH: { n: '欣延加' },
            IR: { n: '伊林加' }
        }
    },
    TON: {
        n: '汤加',
        0: {
            n: '',
            E: { n: '埃瓦' },
            H: { n: '哈派' },
            N: { n: '纽阿斯' },
            T: { n: '汤加塔布' },
            V: { n: '瓦瓦乌' }
        }
    },
    TCA: { n: '特克斯和凯克特斯群岛', 0: { n: '', 0: { n: '' } } },
    TAA: { n: '特里斯坦达昆哈', 0: { n: '', 0: { n: '' } } },
    TTO: { n: '特立尼达和多巴哥', 0: { n: '', 0: { n: '' } } },
    TUN: {
        n: '突尼斯',
        0: {
            n: '',
            AR: { n: '艾尔亚奈' },
            BJ: { n: '巴杰' },
            BA: { n: '本阿鲁斯' },
            BI: { n: '比塞大' },
            KB: { n: '吉比利' },
            GB: { n: '加贝斯' },
            GF: { n: '加夫萨' },
            JE: { n: '坚杜拜' },
            LK: { n: '卡夫' },
            KS: { n: '卡塞林' },
            KR: { n: '凯鲁万' },
            MH: { n: '马赫迪耶' },
            MN: { n: '马努巴' },
            ME: { n: '梅德宁' },
            MO: { n: '莫纳斯提尔' },
            NA: { n: '纳布勒' },
            SF: { n: '斯法克斯' },
            SO: { n: '苏塞' },
            TA: { n: '泰塔温' },
            TU: { n: '突尼斯' },
            TO: { n: '托泽尔' },
            SD: { n: '西迪布济德' },
            SL: { n: '锡勒亚奈' },
            ZA: { n: '宰格万' }
        }
    },
    TUV: { n: '图瓦卢', 0: { n: '', 0: { n: '' } } },
    TUR: {
        n: '土耳其',
        0: {
            n: '',
            ADA: { n: '阿达纳' },
            ADI: { n: '阿德亚曼' },
            ARD: { n: '阿尔达罕' },
            ART: { n: '阿尔特温' },
            AFY: { n: '阿菲永' },
            AKS: { n: '阿克萨赖' },
            AGR: { n: '阿勒' },
            AMA: { n: '阿马西亚' },
            EDI: { n: '埃迪尔内' },
            EZC: { n: '埃尔津詹' },
            EZR: { n: '埃尔祖鲁姆' },
            ELA: { n: '埃拉泽' },
            ESK: { n: '埃斯基谢希尔' },
            AYI: { n: '艾登' },
            ANK: { n: '安卡拉' },
            ANT: { n: '安塔利亚' },
            ORD: { n: '奥尔杜' },
            BAR: { n: '巴尔腾' },
            BAL: { n: '巴勒克埃西尔' },
            BAT: { n: '巴特曼' },
            BAY: { n: '巴伊布尔特' },
            BIL: { n: '比莱吉克' },
            BIT: { n: '比特利斯' },
            BIN: { n: '宾格尔' },
            BOL: { n: '博卢' },
            BRD: { n: '布尔杜尔' },
            BRS: { n: '布尔萨' },
            CKR: { n: '昌克勒' },
            DEN: { n: '代尼兹利' },
            DIY: { n: '迪亚巴克尔' },
            VAN: { n: '凡' },
            HKR: { n: '哈卡里' },
            HTY: { n: '哈塔伊' },
            KLS: { n: '基利斯' },
            GIR: { n: '吉雷松' },
            GAZ: { n: '加济安泰普' },
            GMS: { n: '居米什哈内' },
            KRS: { n: '卡尔斯' },
            KAH: { n: '卡赫拉曼马拉什' },
            KRB: { n: '卡拉比克' },
            KRM: { n: '卡拉曼' },
            KAS: { n: '卡斯塔莫努' },
            KAY: { n: '开塞利' },
            KOC: { n: '科贾埃利' },
            KLR: { n: '柯克拉雷利' },
            KON: { n: '科尼亚' },
            KRH: { n: '克尔谢希尔' },
            KRK: { n: '克勒克卡莱' },
            URF: { n: '拉飞' },
            RIZ: { n: '里泽' },
            MAR: { n: '马尔丁' },
            MAL: { n: '马拉蒂亚' },
            MAN: { n: '马尼萨' },
            MUG: { n: '穆拉' },
            MUS: { n: '穆什' },
            NEV: { n: '内夫谢希尔' },
            NIG: { n: '尼代' },
            CKL: { n: '恰纳卡莱' },
            COR: { n: '乔鲁姆' },
            KUT: { n: '屈塔希亚' },
            SAK: { n: '萨卡里亚' },
            SAM: { n: '萨姆松' },
            TEL: { n: '泰基尔达' },
            TRA: { n: '特拉布宗' },
            TUN: { n: '通杰利' },
            TOK: { n: '托卡特' },
            USK: { n: '乌萨克' },
            SIR: { n: '锡尔纳克' },
            SII: { n: '锡尔特' },
            SIN: { n: '锡诺普' },
            SIV: { n: '锡瓦斯' },
            IGD: { n: '伊迪尔' },
            ICE: { n: '伊切尔' },
            ISP: { n: '伊斯帕尔塔' },
            IST: { n: '伊斯坦布尔' },
            IZM: { n: '伊兹密尔' },
            YOZ: { n: '约兹加特' },
            ZON: { n: '宗古尔达克' }
        }
    },
    TKM: {
        n: '土库曼斯坦',
        0: {
            n: '',
            A: { n: '阿哈尔' },
            ASB: { n: '阿什哈巴德市' },
            B: { n: '巴尔坎' },
            D: { n: '达沙古兹' },
            L: { n: '列巴普' },
            M: { n: '马雷' },
            NEB: { n: '涅比特达格' }
        }
    },
    TKL: { n: '托克劳', 0: { n: '', 0: { n: '' } } },
    WLF: { n: '瓦利斯和福图纳', 0: { n: '', 0: { n: '' } } },
    VUT: {
        n: '瓦努阿图',
        0: {
            n: '',
            MA: { n: '马朗帕' },
            PE: { n: '彭纳马' },
            SA: { n: '桑马' },
            TA: { n: '塔菲阿' },
            TO: { n: '托尔巴' },
            SH: { n: '谢法' }
        }
    },
    GTM: {
        n: '危地马拉',
        0: {
            n: '',
            PR: { n: '埃尔普罗格雷索' },
            ES: { n: '埃斯昆特拉' },
            JA: { n: '哈拉帕' },
            JU: { n: '胡蒂亚帕' },
            QC: { n: '基切' },
            QZ: { n: '克萨尔特南戈' },
            RE: { n: '雷塔卢莱乌' },
            MIX: { n: '米克斯科' },
            PE: { n: '佩滕' },
            CQ: { n: '奇基穆拉' },
            CM: { n: '奇马尔特南戈' },
            ZA: { n: '萨卡帕' },
            ST: { n: '萨卡特佩克斯' },
            AV: { n: '上韦拉帕斯' },
            SR: { n: '圣罗莎' },
            SM: { n: '圣马科斯' },
            SU: { n: '苏奇特佩克斯' },
            SO: { n: '索洛拉' },
            TO: { n: '托托尼卡潘' },
            GU: { n: '危地马拉' },
            HU: { n: '韦韦特南戈' },
            BV: { n: '下韦拉帕斯' },
            VIN: { n: '新城' },
            IZ: { n: '伊萨瓦尔' }
        }
    },
    VIR: { n: '维尔京群岛，美属', 0: { n: '', 0: { n: '' } } },
    VGB: { n: '维尔京群岛，英属', 0: { n: '', 0: { n: '' } } },
    VEN: {
        n: '委内瑞拉',
        0: {
            n: '',
            D: { n: '阿拉瓜' },
            Y: { n: '阿马库罗三角洲' },
            C: { n: '阿普雷' },
            B: { n: '安索阿特吉' },
            E: { n: '巴里纳斯' },
            F: { n: '玻利瓦尔' },
            P: { n: '波图格萨' },
            I: { n: '法尔孔' },
            J: { n: '瓜里科' },
            A: { n: '加拉加斯' },
            G: { n: '卡拉沃沃' },
            H: { n: '科赫德斯' },
            K: { n: '拉腊' },
            W: { n: '联邦属地' },
            L: { n: '梅里达' },
            M: { n: '米兰达' },
            N: { n: '莫纳加斯' },
            R: { n: '苏克雷' },
            V: { n: '苏利亚' },
            S: { n: '塔奇拉' },
            T: { n: '特鲁希略' },
            O: { n: '新埃斯帕塔' },
            U: { n: '亚拉奎' },
            Z: { n: '亚马孙' }
        }
    },
    BRN: { n: '文莱', 0: { n: '', 0: { n: '' } } },
    UGA: {
        n: '乌干达',
        0: {
            n: '',
            ARU: { n: '阿鲁阿' },
            APC: { n: '阿帕克' },
            ADJ: { n: '阿朱马尼' },
            BUN: { n: '本迪布焦' },
            BUG: { n: '布吉里' },
            BUS: { n: '布西亚' },
            BSH: { n: '布谢尼' },
            NTU: { n: '恩通加莫' },
            GUL: { n: '古卢' },
            HOI: { n: '霍伊马' },
            KBA: { n: '基巴莱' },
            KIB: { n: '基博加' },
            KYE: { n: '基恩乔乔' },
            KIS: { n: '基索罗' },
            KIT: { n: '基特古姆' },
            JIN: { n: '金贾' },
            KBL: { n: '卡巴莱' },
            KAR: { n: '卡巴罗莱' },
            KAB: { n: '卡贝拉马伊多' },
            KAL: { n: '卡兰加拉' },
            KAM: { n: '卡姆文盖' },
            KML: { n: '卡穆利' },
            KAN: { n: '卡农古' },
            KPC: { n: '卡普乔鲁瓦' },
            KAS: { n: '卡塞塞' },
            KTK: { n: '卡塔奎' },
            KAY: { n: '卡永加' },
            KMP: { n: '坎帕拉' },
            KOT: { n: '科蒂多' },
            KUM: { n: '库米' },
            RAK: { n: '拉卡伊' },
            LIR: { n: '利拉' },
            LUW: { n: '卢韦罗' },
            RUK: { n: '鲁昆吉里' },
            MAS: { n: '马萨卡' },
            MSN: { n: '马辛迪' },
            MAY: { n: '马尤盖' },
            MRT: { n: '莫罗托' },
            MOY: { n: '莫约' },
            MBR: { n: '姆巴拉拉' },
            MBA: { n: '姆巴莱' },
            MPI: { n: '姆皮吉' },
            MUB: { n: '穆本德' },
            MUK: { n: '穆科诺' },
            NAK: { n: '纳卡皮里皮里特' },
            NKS: { n: '纳卡松戈拉' },
            NEB: { n: '内比' },
            PAD: { n: '帕德尔' },
            PAL: { n: '帕利萨' },
            SEM: { n: '森巴布莱' },
            SOR: { n: '索罗提' },
            TOR: { n: '托罗罗' },
            WAK: { n: '瓦基索' },
            SIR: { n: '锡龙科' },
            IGA: { n: '伊甘加' },
            YUM: { n: '永贝' }
        }
    },
    UKR: {
        n: '乌克兰',
        0: {
            n: '',
            51: { n: '敖德萨' },
            53: { n: '波尔塔瓦' },
            12: { n: '第聂伯罗波得罗夫斯克' },
            14: { n: '顿涅茨克' },
            63: { n: '哈尔科夫' },
            65: { n: '赫尔松州' },
            68: { n: '赫梅利尼茨基' },
            30: { n: '基辅' },
            35: { n: '基洛夫格勒' },
            61: { n: '捷尔诺波尔' },
            43: { n: '克里米亚自治共和国' },
            46: { n: '利沃夫' },
            9: { n: '卢甘斯克' },
            56: { n: '罗夫诺' },
            48: { n: '尼古拉耶夫' },
            71: { n: '切尔卡瑟' },
            74: { n: '切尔尼戈夫' },
            77: { n: '切尔诺夫策' },
            18: { n: '日托米尔' },
            59: { n: '苏梅' },
            21: { n: '外喀尔巴阡' },
            5: { n: '文尼察' },
            7: { n: '沃伦' },
            26: { n: '伊万－弗兰科夫州' },
            23: { n: '扎波罗热' }
        }
    },
    URY: {
        n: '乌拉圭',
        0: {
            n: '',
            AR: { n: '阿蒂加斯' },
            DU: { n: '杜拉斯诺' },
            FA: { n: '佛罗里达' },
            FS: { n: '弗洛雷斯' },
            CA: { n: '卡内洛内斯' },
            CO: { n: '科洛尼亚' },
            LA: { n: '拉瓦耶哈' },
            RV: { n: '里韦拉' },
            RO: { n: '罗恰' },
            MA: { n: '马尔多纳多' },
            MO: { n: '蒙得维的亚' },
            RN: { n: '内格罗河' },
            PA: { n: '派桑杜' },
            SL: { n: '萨尔托' },
            CL: { n: '塞罗拉尔戈' },
            TT: { n: '三十三人' },
            SJ: { n: '圣何塞' },
            SO: { n: '索里亚诺' },
            TAW: { n: '塔夸伦博' }
        }
    },
    UZB: {
        n: '乌兹别克斯坦',
        0: {
            n: '',
            AN: { n: '安集延' },
            BU: { n: '布哈拉' },
            FA: { n: '费尔干纳' },
            XO: { n: '花拉子模' },
            JI: { n: '吉扎克' },
            QR: { n: '卡拉卡尔帕克斯坦共和国' },
            QA: { n: '卡什卡达里亚' },
            NG: { n: '纳曼干' },
            NW: { n: '纳沃伊' },
            SA: { n: '撒马尔罕' },
            SU: { n: '苏尔汉河' },
            TK: { n: '塔什干' },
            TO: { n: '塔什干市' },
            SI: { n: '锡尔河' }
        }
    },
    ESP: {
        n: '西班牙',
        0: {
            n: '',
            LEI: { n: '阿尔梅里亚' },
            ALB: { n: '阿尔瓦塞特' },
            ALA: { n: '阿拉瓦' },
            ALC: { n: '阿利坎特' },
            AST: { n: '阿斯图利亚斯' },
            AVI: { n: '阿维拉' },
            ORE: { n: '奥伦塞' },
            BJZ: { n: '巴达霍斯' },
            BLR: { n: '巴利阿里' },
            VLL: { n: '巴利亚多利德' },
            VLC: { n: '巴伦西亚' },
            BCN: { n: '巴塞罗那' },
            VSE: { n: '比斯开' },
            BUR: { n: '布尔戈斯' },
            GRX: { n: '格拉纳达' },
            GUA: { n: '瓜达拉哈拉' },
            JAE: { n: '哈恩' },
            GRO: { n: '赫罗纳' },
            GUI: { n: '吉普斯夸' },
            CAD: { n: '加的斯' },
            CCS: { n: '卡塞雷斯' },
            CIR: { n: '卡斯蒂利亚' },
            CAS: { n: '卡斯特利翁' },
            ODB: { n: '科尔多瓦' },
            CUE: { n: '昆卡' },
            LCG: { n: '拉科鲁尼亚' },
            ARL: { n: '拉里奥哈' },
            LPA: { n: '拉斯帕尔马斯' },
            LEN: { n: '莱昂' },
            LLE: { n: '莱里达' },
            LGO: { n: '卢戈' },
            MAD: { n: '马德里' },
            AGP: { n: '马拉加' },
            MJV: { n: '穆尔西亚' },
            NVV: { n: '纳瓦拉' },
            PAC: { n: '帕伦西亚' },
            PEV: { n: '蓬特韦德拉' },
            ZAZ: { n: '萨拉戈萨' },
            SLM: { n: '萨拉曼卡' },
            ZMR: { n: '萨莫拉' },
            SEG: { n: '塞哥维亚' },
            SVQ: { n: '塞维利亚' },
            SDR: { n: '桑坦德' },
            SCT: { n: '圣克鲁斯-德特内里费' },
            SOR: { n: '索里亚' },
            TAR: { n: '塔拉戈纳' },
            TER: { n: '特鲁埃尔' },
            TOL: { n: '托莱多' },
            HUV: { n: '韦尔瓦' },
            HUC: { n: '韦斯卡' }
        }
    },
    GRC: {
        n: '希腊',
        0: {
            n: '',
            PRI: { n: '比雷埃夫斯' },
            DO: { n: '多德卡尼斯' },
            CHQ: { n: '干尼亚' },
            CY: { n: '基克拉迪' },
            LST: { n: '拉西锡' },
            LES: { n: '莱斯博斯' },
            RET: { n: '雷西姆农' },
            SMI: { n: '萨摩斯' },
            ATH: { n: '雅典' },
            HER: { n: '伊拉克里翁' }
        }
    },
    SGP: { n: '新加坡', 0: { n: '', 0: { n: '' } } },
    NCL: { n: '新喀里多尼亚', 0: { n: '', 0: { n: '' } } },
    NZL: {
        n: '新西兰',
        0: {
            n: '',
            AUK: { n: '奥克兰' },
            NSH: { n: '北岸' },
            PMR: { n: '北帕默斯顿' },
            FNR: { n: '北远' },
            BHE: { n: '布莱尼姆' },
            DUD: { n: '达尼丁' },
            GMN: { n: '格雷茅斯' },
            HLZ: { n: '哈密尔顿' },
            HAS: { n: '黑斯廷斯' },
            WAE: { n: '怀塔科拉' },
            GIS: { n: '吉斯伯恩' },
            KAI: { n: '凯帕拉' },
            CHC: { n: '克赖斯特彻奇' },
            RMD: { n: '里士满' },
            MNK: { n: '马努考' },
            NSN: { n: '纳尔逊' },
            NPE: { n: '内皮尔' },
            STR: { n: '斯特拉特福德' },
            TAU: { n: '陶马鲁努伊' },
            WHK: { n: '瓦卡塔尼' },
            WRE: { n: '旺阿雷' },
            WAG: { n: '旺格努伊' },
            NPL: { n: '新普利茅斯' },
            IVC: { n: '因弗卡吉尔' }
        }
    },
    HUN: {
        n: '匈牙利',
        0: {
            n: '',
            BA: { n: '巴兰尼亚' },
            BK: { n: '巴奇-基什孔' },
            BZ: { n: '包尔绍德-奥包乌伊-曾普伦' },
            BE: { n: '贝凯什' },
            BU: { n: '布达佩斯' },
            FE: { n: '费耶尔' },
            HB: { n: '豪伊杜-比豪尔' },
            HE: { n: '赫维什' },
            JN: { n: '加兹-纳杰孔-索尔诺克' },
            GS: { n: '杰尔-莫松-肖普朗' },
            KE: { n: '科马罗姆' },
            NO: { n: '诺格拉德' },
            PE: { n: '佩斯' },
            CS: { n: '琼格拉德' },
            SO: { n: '绍莫吉' },
            SZ: { n: '索博尔奇-索特马尔-贝拉格' },
            TO: { n: '托尔瑙' },
            VE: { n: '维斯普雷姆' },
            VA: { n: '沃什' },
            ZA: { n: '佐洛' }
        }
    },
    SYR: {
        n: '叙利亚',
        0: {
            n: '',
            HL: { n: '阿勒颇' },
            RD: { n: '大马士革' },
            DI: { n: '大马士革市' },
            DZ: { n: '代尔祖尔' },
            DA: { n: '德拉' },
            HM: { n: '哈马' },
            HA: { n: '哈塞克' },
            HI: { n: '霍姆斯' },
            GH: { n: '加布' },
            QA: { n: '卡米什利' },
            QU: { n: '库奈特拉' },
            RQ: { n: '拉卡' },
            LA: { n: '拉塔基亚' },
            SU: { n: '苏韦达' },
            TA: { n: '塔尔图斯' },
            ID: { n: '伊德利卜' }
        }
    },
    JAM: {
        n: '牙买加',
        0: {
            n: '',
            POR: { n: '波特兰' },
            HAN: { n: '汉诺威' },
            KIN: { n: '金斯敦' },
            CLA: { n: '克拉伦登' },
            MAN: { n: '曼彻斯特' },
            AND: { n: '圣安德鲁斯' },
            ANN: { n: '圣安娜' },
            CAT: { n: '圣凯瑟琳' },
            MAR: { n: '圣玛丽' },
            THO: { n: '圣托马斯' },
            ELI: { n: '圣伊丽莎白' },
            JAM: { n: '圣詹姆斯' },
            TRL: { n: '特里洛尼' },
            WML: { n: '西摩兰' }
        }
    },
    ARM: {
        n: '亚美尼亚',
        0: {
            n: '',
            ARM: { n: '阿尔马维尔' },
            AGT: { n: '阿拉加措特恩' },
            ARA: { n: '阿拉拉特' },
            EVN: { n: '埃里温市' },
            GEG: { n: '格加尔库尼克' },
            KOT: { n: '科泰克' },
            LOR: { n: '洛里' },
            TAV: { n: '塔武什' },
            VAY: { n: '瓦约茨·佐尔' },
            SHI: { n: '希拉克' },
            SYU: { n: '休尼克' }
        }
    },
    YEM: {
        n: '也门',
        0: {
            n: '',
            AB: { n: '阿比扬' },
            AM: { n: '阿姆兰' },
            BA: { n: '贝达' },
            DA: { n: '达利' },
            HD: { n: '哈德拉毛' },
            HJ: { n: '哈杰' },
            HU: { n: '荷台达' },
            JA: { n: '焦夫' },
            LA: { n: '拉赫季' },
            MA: { n: '马里卜' },
            MR: { n: '迈赫拉' },
            MW: { n: '迈赫维特' },
            SD: { n: '萨达' },
            SN: { n: '萨那' },
            GXF: { n: '赛文' },
            SH: { n: '舍卜沃' },
            TA: { n: '塔伊兹' },
            ASR: { n: '希赫尔' },
            AD: { n: '亚丁' },
            IB: { n: '伊卜' },
            DH: { n: '扎玛尔' }
        }
    },
    IRQ: { n: '伊拉克', 0: { n: '', 0: { n: '' } } },
    IRN: { n: '伊朗', 0: { n: '', 0: { n: '' } } },
    ISR: {
        n: '以色列',
        0: {
            n: '',
            ASH: { n: '阿什杜德' },
            BEV: { n: '贝尔谢巴' },
            BAT: { n: '贝特雁' },
            HFA: { n: '海法' },
            HOL: { n: '霍隆' },
            NAT: { n: '内坦亚' },
            TLV: { n: '特拉维夫' },
            J: { n: '耶路撒冷' }
        }
    },
    ITA: {
        n: '意大利',
        0: {
            n: '',
            AST: { n: '阿斯蒂' },
            ASP: { n: '阿斯科利皮切诺' },
            AOI: { n: '安科纳' },
            OLB: { n: '奥尔比亚' },
            QOS: { n: '奥里斯塔诺' },
            AOT: { n: '奥斯塔' },
            PMO: { n: '巴勒莫' },
            BRI: { n: '巴里' },
            BGO: { n: '贝加莫' },
            BEN: { n: '贝内文托' },
            PSA: { n: '比萨' },
            PRD: { n: '波代诺内' },
            QPO: { n: '波坦察' },
            BLQ: { n: '博洛尼亚' },
            BIE: { n: '布拉' },
            BRC: { n: '布雷西亚' },
            BDS: { n: '布林迪西' },
            TRS: { n: '的里雅斯特' },
            TRN: { n: '都灵' },
            FRR: { n: '费拉拉' },
            FLR: { n: '佛罗伦萨' },
            FOG: { n: '福贾' },
            CAG: { n: '卡利亚里' },
            CST: { n: '卡塞塔' },
            CTA: { n: '卡塔尼亚' },
            QCZ: { n: '卡坦扎罗' },
            COB: { n: '坎波巴索' },
            CIY: { n: '科摩' },
            QCS: { n: '科森扎' },
            CRV: { n: '克罗托内' },
            CUN: { n: '库内奥' },
            LAQ: { n: '拉奎拉' },
            SPE: { n: '拉斯佩齐亚' },
            LCO: { n: '莱科' },
            LCC: { n: '莱切' },
            RNE: { n: '雷焦艾米利亚' },
            REG: { n: '雷焦卡拉布里亚' },
            LIV: { n: '里窝那' },
            ROM: { n: '罗马' },
            MCR: { n: '马萨' },
            MTR: { n: '马泰拉' },
            MZA: { n: '蒙扎' },
            MIL: { n: '米兰' },
            MOD: { n: '摩德纳' },
            MSN: { n: '墨西拿' },
            NAP: { n: '那不勒斯' },
            QNU: { n: '努奥罗' },
            NVR: { n: '诺瓦拉' },
            PMF: { n: '帕尔马' },
            PAV: { n: '帕维亚' },
            PEG: { n: '佩鲁贾' },
            CAX: { n: '热那亚' },
            SAL: { n: '萨莱诺' },
            QSS: { n: '萨萨里' },
            SVN: { n: '萨沃纳' },
            TAR: { n: '塔兰托' },
            TPS: { n: '特拉帕尼' },
            TRT: { n: '特伦托' },
            VCE: { n: '威尼斯' },
            VRL: { n: '韦尔切利' },
            VIT: { n: '维泰博' },
            UDN: { n: '乌迪内' },
            SYR: { n: '锡拉库扎' },
            SNA: { n: '锡耶纳' },
            ALE: { n: '亚历山德里亚' },
            ISE: { n: '伊塞尔尼亚' }
        }
    },
    IND: {
        n: '印度',
        0: {
            n: '',
            AJL: { n: '艾藻尔' },
            BLR: { n: '班加罗尔' },
            PNY: { n: '本地治里' },
            BHO: { n: '博帕尔' },
            BBI: { n: '布巴内斯瓦尔' },
            IXC: { n: '昌迪加尔' },
            DAM: { n: '达曼' },
            DIU: { n: '第乌' },
            GTO: { n: '甘托克' },
            CJB: { n: '哥印拜陀' },
            CCU: { n: '加尔各答' },
            KRK: { n: '加里加尔' },
            JLR: { n: '贾巴尔普尔' },
            JUC: { n: '贾朗达尔' },
            JDH: { n: '焦特布尔' },
            MAA: { n: '金奈' },
            KVA: { n: '卡瓦拉蒂' },
            KOM: { n: '科希马' },
            MAH: { n: '马埃' },
            IXM: { n: '马杜赖' },
            SLR: { n: '森伯尔布尔' },
            TRV: { n: '特里凡得琅' },
            UDR: { n: '乌代布尔' },
            SHL: { n: '西隆' },
            SIL: { n: '锡尔萨瓦' },
            ICD: { n: '新德里' },
            SRV: { n: '亚南' },
            IMF: { n: '因帕尔' },
            IDR: { n: '印多尔' },
            JAI: { n: '斋普尔' }
        }
    },
    IDN: {
        n: '印度尼西亚',
        0: {
            n: '',
            BA: { n: '巴厘' },
            BB: { n: '邦加－勿里洞群岛' },
            SA: { n: '北苏拉威西' },
            SU: { n: '北苏门答腊' },
            KB: { n: '大雅加达首都特区' },
            KI: { n: '东加里曼丹' },
            SG: { n: '东南苏拉威西' },
            NT: { n: '东努沙登加拉' },
            JI: { n: '东爪哇' },
            RI: { n: '廖内' },
            MA: { n: '马鲁古' },
            BE: { n: '明古鲁' },
            LA: { n: '楠榜' },
            KS: { n: '南加里曼丹' },
            SN: { n: '南苏拉威西' },
            SS: { n: '南苏门答腊' },
            YO: { n: '日惹特区' },
            BT: { n: '万丹' },
            NB: { n: '西努沙登加拉' },
            SR: { n: '西苏门答腊' },
            JB: { n: '西爪哇' },
            JK: { n: '雅加达' },
            AC: { n: '亚齐' },
            IJ: { n: '伊里安查亚' },
            JA: { n: '占碑' },
            KT: { n: '中加里曼丹' },
            ST: { n: '中苏拉威西' },
            JT: { n: '中爪哇' }
        }
    },
    GBR: {
        n: '英国',
        NIR: {
            n: '北爱尔兰',
            BFS: { n: '贝尔法斯特' },
            DRY: { n: '德里' },
            LSB: { n: '利斯本' },
            NYM: { n: '纽里' }
        },
        SCT: {
            n: '苏格兰',
            ABD: { n: '阿伯丁' },
            EDH: { n: '爱丁堡' },
            DND: { n: '丹迪' },
            GLG: { n: '格拉斯哥' },
            STG: { n: '斯特灵' },
            INV: { n: '因弗内斯' }
        },
        WLS: {
            n: '威尔士',
            BAN: { n: '班戈' },
            CDF: { n: '卡迪夫' },
            NWP: { n: '纽波特' },
            SWA: { n: '斯旺西' }
        },
        ENG: {
            n: '英格兰',
            EXE: { n: '埃克塞特' },
            BAS: { n: '巴斯' },
            PTE: { n: '彼得伯勒' },
            BIR: { n: '伯明翰' },
            BRD: { n: '布拉德福德' },
            BNH: { n: '布莱顿与赫福' },
            BST: { n: '布里斯托尔' },
            DER: { n: '德比' },
            DUR: { n: '德罕' },
            GLO: { n: '格洛斯特' },
            KUH: { n: '赫尔河畔京斯敦' },
            HAF: { n: '赫里福德' },
            CAM: { n: '剑桥' },
            CAX: { n: '卡莱尔' },
            CNG: { n: '坎特伯雷' },
            COV: { n: '考文垂' },
            LAN: { n: '兰开斯特' },
            RIP: { n: '里彭' },
            LHF: { n: '利奇菲尔德' },
            LIV: { n: '利物浦' },
            LDS: { n: '利茲' },
            LCE: { n: '列斯特' },
            LCN: { n: '林肯' },
            LND: { n: '伦敦' },
            MAN: { n: '曼彻斯特' },
            STH: { n: '南安普敦' },
            OXF: { n: '牛津' },
            NCL: { n: '纽卡斯尔' },
            NGM: { n: '诺丁汉' },
            NRW: { n: '诺里奇' },
            POR: { n: '朴茨茅斯' },
            PRE: { n: '普雷斯顿' },
            PLY: { n: '普利茅斯' },
            CST: { n: '奇切斯特' },
            CEG: { n: '切斯特' },
            SUN: { n: '桑德兰' },
            TBL: { n: '圣阿本斯' },
            SLS: { n: '索尔斯堡' },
            SLF: { n: '索福特' },
            TRU: { n: '特鲁罗' },
            SOT: { n: '特伦特河畔斯多克' },
            WLS: { n: '威尔斯' },
            WKF: { n: '韦克菲尔德' },
            WNE: { n: '温彻斯特' },
            WOV: { n: '伍尔弗汉普顿' },
            WOR: { n: '伍斯特' },
            SHE: { n: '谢菲尔德' },
            ELY: { n: '伊利' },
            YOR: { n: '约克' }
        }
    },
    IOT: { n: '英属印度洋领地', 0: { n: '', 0: { n: '' } } },
    JOR: {
        n: '约旦',
        0: {
            n: '',
            AJ: { n: '阿吉隆' },
            AM: { n: '安曼' },
            BA: { n: '拜勒加' },
            JA: { n: '杰拉什' },
            KA: { n: '卡拉克' },
            RU: { n: '鲁赛法' },
            MN: { n: '马安' },
            MD: { n: '马德巴' },
            MF: { n: '马夫拉克' },
            TA: { n: '塔菲拉' },
            AQ: { n: '亚喀巴' },
            IR: { n: '伊尔比德' },
            ZA: { n: '扎尔卡' }
        }
    },
    VNM: {
        n: '越南',
        0: {
            n: '',
            HP: { n: '海防' },
            HI: { n: '河内' },
            HC: { n: '胡志明市' }
        }
    },
    ZMB: {
        n: '赞比亚',
        0: {
            n: '',
            NO: { n: '北方' },
            EA: { n: '东方' },
            LP: { n: '卢阿普拉' },
            LK: { n: '卢萨卡' },
            SO: { n: '南方' },
            CB: { n: '铜带' },
            NW: { n: '西北' },
            WE: { n: '西方' },
            CE: { n: '中央' }
        }
    },
    JEY: { n: '泽西岛', 0: { n: '', 0: { n: '' } } },
    TCD: { n: '乍得', 0: { n: '', 0: { n: '' } } },
    GIB: { n: '直布罗陀', 0: { n: '', 0: { n: '' } } },
    CHL: {
        n: '智利',
        0: {
            n: '',
            AR: { n: '阿劳卡尼亚大区' },
            AT: { n: '阿塔卡马大区' },
            AN: { n: '安托法加斯塔大区' },
            BI: { n: '比奥比奥大区' },
            LI: { n: '复活节岛' },
            LL: { n: '湖大区' },
            CO: { n: '科金博大区' },
            ML: { n: '马乌莱大区' },
            MA: { n: '麦哲伦-智利南极大区' },
            RM: { n: '圣地亚哥' },
            TA: { n: '塔拉帕卡大区' },
            VS: { n: '瓦尔帕莱索大区' },
            AI: { n: '伊瓦涅斯将军的艾森大区' }
        }
    },
    CAF: {
        n: '中非共和国',
        0: {
            n: '',
            BB: { n: '巴明吉-班戈兰' },
            BGF: { n: '班吉直辖市' },
            BI: { n: '宾博' },
            KG: { n: '凯莫' },
            LB: { n: '洛巴伊' },
            HS: { n: '曼贝雷-卡代' },
            MB: { n: '姆博穆' },
            KB: { n: '纳纳-格里比齐' },
            NM: { n: '纳纳-曼贝雷' },
            SE: { n: '桑加-姆巴埃雷' },
            HK: { n: '上科托' },
            HM: { n: '上姆博穆' },
            UK: { n: '瓦卡' },
            VK: { n: '瓦卡加' },
            AC: { n: '瓦姆' },
            OP: { n: '瓦姆-彭代' },
            MP: { n: '翁贝拉-姆波科' },
            BK: { n: '下科托' }
        }
    },
    0: { n: '', 0: { n: '', 0: { n: '' } } }
};

export { phonePreList, countrys };
