<template>
    <el-input
        v-model="phone"
        :placeholder="pla"
        class="input2 phone"
        @input="changeVal"
        :maxlength="11"
        clearable
        :disabled="readonly"
    >
        <el-select v-model="phoneType" v-if="select" slot="prepend">
            <el-option
                v-for="(item, index) in phonePreList"
                :key="index"
                :label="item.c + ' ' + item.p"
                :value="item.c"
                :disabled="item.c !== '+86'"
            >
                <span style="float: left">{{ item.n }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{
                    item.c
                }}</span>
            </el-option>
        </el-select>
    </el-input>
</template>

<script>
import { phonePreList } from '../utils/World';
export default {
    name: 'phone',
    props: {
        value: {},
        defaultPhone: {},
        readonly: {
            type: Boolean,
            default: false
        },
        select: {
            type: Boolean,
            default: true
        },
        small: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ''
        }
    },
    data() {
        return { phoneType: '+86', phone: '', pla: '' };
    },
    watch: {
        value() {
            if (!this.value) {
                this.phone = '';
            }
        },
        placeholder() {
            if (this.placeholder) {
                this.pla = this.placeholder;
            }
        }
    },
    computed: {
        phonePreList() {
            return phonePreList.map(item => {
                return {
                    ...item,
                    n: this.$i18n.t(item.n)
                };
            });
        }
    },
    methods: {
        changeVal() {
            this.$emit('input', this.phoneType + this.phone);
        }
    },
    mounted() {
        this.$nextTick(() => {
            if (this.defaultPhone) {
                this.phone = this.defaultPhone;
                this.changeVal();
            }
            this.pla =
                this.placeholder || this.$t('qing-shu-ru-zhu-ce-shou-ji-hao');
        });
    }
};
</script>

<style lang="scss">
.el-input.is-disabled .el-input__inner {
    color: #292c33;
    cursor: inherit;
}

.phone {
    .el-input-group__prepend {
        width: 60px;
        background-color: transparent;
    }
}
</style>
